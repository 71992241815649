import { AxiosInstance } from "axios";
import { UserDto } from "./dto/user";

/** PYTHON */
/** PYTHON */
/** PYTHON */

export const postTextToAnswer = async (
  api: AxiosInstance,
  messages: {
    role?: string;
    content?: string;
    language?: string;
  }[],
  uservalues: any
) => {
  const response = await api.post("/texttoanswer", { messages, uservalues });
  return response.data;
};

export const postTextToAnswerText = async (
  api: AxiosInstance,
  messages: {
    role?: string;
    content?: string;
    language?: string;
  }[],
  uservalues: any
) => {
  const response = await api.post("/texttoanswertext", {
    messages,
    uservalues,
  });
  return response.data;
};

export const postStartCall = async (
  api: AxiosInstance,
  sessionId: string,
  uservalues: any,
  language: string
) => {
  const response = await api.post("/launchcall", {
    sessionId,
    uservalues,
    language,
  });
  return response.data;
};

export const postIntroductionMessage = async (
  api: AxiosInstance,
  sessionId: string,
  uservalues: any
) => await api.post("/introductionmessage", { sessionId, uservalues });

export const postTranslateIntroduction = async (
  api: AxiosInstance,
  language: string,
  introduction: string,
  botAskPhoneContact: boolean,
  botAskEmailContact: boolean
) =>
  await api.post("/translateintroduction", {
    language,
    introduction,
    botAskPhoneContact,
    botAskEmailContact,
  });

export const postEFKmessage = async (
  api: AxiosInstance,
  message: { subject?: string; content?: string; infos?: string }
) => {
  const response = await api.post("/efk", { message });
  return response.data;
};

/** NEST PRIVATE */
/** NEST PRIVATE */
/** NEST PRIVATE */

export const getMe = async (api: AxiosInstance) => {
  const response = await api.get("/auth/me");
  return response.data;
};

export const getUserData = async (api: AxiosInstance) => api.get("/user");

export const postUserData = async (api: AxiosInstance, formValues: any) =>
  api.post("/user", { formValues });

export const getMessages = async (api: AxiosInstance) =>
  await api.get(`/messages`);

export const postNewPassword = async (
  api: AxiosInstance,
  oldPassword: string,
  newPassword: string
) => await api.post(`/auth/newpassword`, { newPassword, oldPassword });

/** NEST PUBLIC */
/** NEST PUBLIC */
/** NEST PUBLIC */

const publicRoutes = [
  { pattern: /\/auth\/login$/, method: "POST" },
  { pattern: /\/auth\/register$/, method: "POST" },
  { pattern: /\/messages$/, method: "POST" },
  { pattern: /\/user\/\d+$/, method: "GET" }, // This will match /user/{number}
];

export function isPublicRoute(url: string, method: string) {
  return publicRoutes.some(
    (route) => route.pattern.test(url) && method === route.method
  );
}

export const saveMessage = async (
  api: AxiosInstance,
  userId: string,
  messages: any,
  href: string
) => await api.post(`/messages`, { userId, messages, href });

export const login = async (
  api: AxiosInstance,
  email: string,
  password: string
) => {
  const response = await api.post("/auth/login", { email, password });
  return response.data;
};

export const register = async (api: AxiosInstance, userDto: UserDto) => {
  const response = await api.post("/auth/register", userDto);
  return response.data;
};

export const getUserDataPublic = async (api: AxiosInstance, id: string) =>
  api.get("/user/" + id);

export const postVoiceBotMessages = async (
  api: AxiosInstance,
  messages: any
) => {
  const response = await api.post("/voicebotmessages", { messages });
  return response.data;
};

export const postVoiceBotMessagesPythonPrompted = async (
  api: AxiosInstance,
  messages: any
) => {
  const response = await api.post("/prompted-chat", { messages });
  return response.data;
};

export const postSubscribe = async (api: AxiosInstance, priceId: any) => {
  const response = await api.post("/subscriptions/subscribe", { priceId });
  return response.data;
};

export const verifyPayment = async (api: AxiosInstance, sessionId: string) =>
  await api.get(`/subscriptions/verify-session?session_id=${sessionId}`);

export const cancelSubscription = async (api: AxiosInstance) =>
  await api.get(`/subscriptions/cancel`);
