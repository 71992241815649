import {
  ActionIcon,
  Avatar,
  Button,
  Center,
  Flex,
  Loader,
  Text,
  Textarea,
  useMantineTheme,
} from "@mantine/core";
import robot from "../VoiLabs/Assets/robot.jpg";
import ring_3s from "../VoiLabs/Assets/ring_3s.mp3";
import { useEffect, useRef, useState } from "react";
import { backendUrlPython, useApiNest, useApiPython } from "../../useApi";
import {
  getUserData,
  getUserDataPublic,
  postIntroductionMessage,
  postStartCall,
  postTextToAnswer,
  postTextToAnswerText,
  postTranslateIntroduction,
  saveMessage,
} from "../../apiRoutes";
import { UserDto } from "../../dto/user";
import { useParams } from "react-router-dom";
import { IconPhone, IconPhoneOff, IconSend } from "@tabler/icons";
import MissingFields from "../MissingFields";
import { EventSourcePolyfill } from "event-source-polyfill";

export const getRandomString = () => {
  var chars = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  var result = "";
  for (var i = 0; i < 10; i++) {
    result += chars[Math.floor(Math.random() * chars.length)];
  }
  return result;
};

const ChatWidget = ({ not100vh = false }: { not100vh?: boolean }) => {
  const { userId } = useParams<{ userId: string }>();
  const [loading, setLoading] = useState(true);
  const [sessionId] = useState(getRandomString());
  const messagesRef = useRef([
    {
      role: "system",
      content:
        "You are an advisor. Please respond in less than 5 complete sentences. Always ask if it was clear of are there other topics he can help with.",
      sessionId,
    },
  ]);
  const [introduction, setIntroduction] = useState("");
  const recognitionRef = useRef<SpeechRecognition | null>(null);
  const [isListening, setIsListening] = useState(false);
  const processRunningRef = useRef(false);
  const [processRunning, setProcessRunning] = useState(false);
  const [duringCall, setDuringCall] = useState(false);
  const apiPython = useApiPython();
  const [boxShadow, setBoxShadow] = useState("");
  const audioRef = useRef<HTMLAudioElement | null>(null);
  const apiNest = useApiNest();
  const [userValues, setUserValues] = useState<UserDto>();
  const theme = useMantineTheme();
  const [message, setMessage] = useState("");
  const messagesEndRef = useRef<HTMLDivElement>(null);
  const language = navigator.language || "en-US";
  const initRef = useRef<number>(0);

  useEffect(() => {
    const eventSource = new EventSourcePolyfill(
      backendUrlPython + "/chatgpt-stream",
      {
        headers: {
          Accept:
            "text/html,application/xhtml+xml,application/xml;q=0.9,image/avif,image/webp,image/apng,*/*;q=0.8,application/signed-exchange;v=b3;q=0.9",
          "X-Messages-Ref": JSON.stringify(messagesRef.current),
        },
      }
    );
    eventSource.onmessage = (event) => {
      const responseObject = JSON.parse(event.data);
      if (messagesRef.current.length === 1) {
        messagesRef.current.push({
          role: "user", // This should be set according to your logic for roles
          content: "",
          sessionId: messagesRef.current[0].sessionId, // Reuse the session ID from the first message
        });
      }
      messagesRef.current[1].content += responseObject["content"] || "";
      messagesRef.current[1].role =
        responseObject["role"] || messagesRef.current[1].role;
    };

    eventSource.onerror = (error) => {
      console.error("Error with SSE connection:", error); // Log any errors
      eventSource.close(); // Close the connection on error
    };

    return () => {
      eventSource.close(); // Clean up the event source when the component is unmounted
    };
  }, []);

  useEffect(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messagesRef.current]);

  useEffect(() => {
    const interval = setInterval(() => {
      const spread = Math.random() * 20;
      const blur = Math.random() * 60;
      setBoxShadow(`0 0 ${blur}px ${spread}px rgba(128, 0, 128, 0.7)`);
    }, 200);

    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const fetchPostStartCall = async () => {
      if (sessionId?.length > 0 && initRef.current === 0) {
        initRef.current = 1;
        const retUser = userId
          ? await getUserDataPublic(apiNest, userId ?? "")
          : await getUserData(apiNest);
        setUserValues(retUser.data);
        const [ret, retTranslatedIntro] = await Promise.all([
          postStartCall(
            apiPython,
            sessionId,
            retUser.data,
            language.slice(0, 2)
          ),
          postTranslateIntroduction(
            apiPython,
            language,
            retUser.data.introduction,
            retUser.data.botAskPhoneContact,
            retUser.data.botAskEmailContact
          ),
        ]);
        setIntroduction(retTranslatedIntro.data.intro_message);
        if (retTranslatedIntro.data.email_phone_message.length) {
          console.log(
            "email_phone_message",
            retTranslatedIntro.data.email_phone_message
          );
          const newMessages = [
            ...messagesRef.current,
            {
              role: "assistant",
              content: retTranslatedIntro.data.email_phone_message,
              sessionId,
              language: language.slice(0, 2),
            },
          ];
          messagesRef.current = newMessages;
        }
        if (ret?.session === sessionId) setLoading(false);
      }
    };
    fetchPostStartCall();
  }, [sessionId, apiPython, apiNest, userId, language]);

  const playAudio = async (audioSrc: string, isBase64: boolean = false) => {
    if (!processRunningRef.current && audioSrc !== ring_3s) {
      return;
    }

    let audioURL = audioSrc;

    if (isBase64) {
      const audioBlob = new Blob(
        [
          new Uint8Array(
            atob(audioSrc)
              .split("")
              .map((c) => c.charCodeAt(0))
          ),
        ],
        { type: "audio/mpeg" }
      );
      audioURL = URL.createObjectURL(audioBlob);
    }

    const audio = new Audio(audioURL);
    audioRef.current = audio;
    try {
      await audio.play();
    } catch (error) {
      console.error(`Failed to play audio ${audioSrc}: `, error);
      return;
    }

    // Wait for the audio to finish playing
    await new Promise<void>((resolve) => {
      audio.onended = () => {
        resolve();
        audioRef.current = null;
      };
    });

    if (
      !processRunningRef.current &&
      audioRef.current &&
      !audioRef.current.paused
    ) {
      audioRef.current.pause();
      audioRef.current = null;
    }
  };

  const resetProcess = () => {
    if (recognitionRef.current) {
      recognitionRef.current.stop();
      recognitionRef.current = null;
    }
    if (audioRef.current && !audioRef.current.paused) {
      audioRef.current.pause();
      audioRef.current = null;
    }
    setDuringCall(false);
    setIsListening(false);
    processRunningRef.current = false;
    setProcessRunning(false);
  };

  const decodeAudioAndPlay = (audioBase64: string) => {
    let audioBlob = new Blob(
      [
        new Uint8Array(
          atob(audioBase64)
            .split("")
            .map(function (c) {
              return c.charCodeAt(0);
            })
        ),
      ],
      { type: "audio/mpeg" }
    );
    let audioURL = URL.createObjectURL(audioBlob);
    let audio = new Audio(audioURL);
    audioRef.current = audio;

    audio.onended = () => {
      if (!isListening) {
        startSpeechRecognition();
      }
    };

    audio.play();
  };

  const sendTranscript = async (finalTranscript: string) => {
    setIsListening(false);
    const newMessages = [
      ...messagesRef.current,
      {
        role: "user",
        content: finalTranscript,
        sessionId,
        language: language.slice(0, 2),
      },
    ];
    messagesRef.current = newMessages;
    const beforeTimestamp = Date.now();
    console.log("postTextToAnswer", newMessages);
    const response = await postTextToAnswer(apiPython, newMessages, userValues);
    const afterTimestamp = Date.now();
    const timeTaken = afterTimestamp - beforeTimestamp; // in milliseconds
    console.log(`postTextToAnswer took ${timeTaken / 1000} seconds`);
    if (!processRunningRef.current) return;
    const responseMessages = response.messages;
    messagesRef.current = responseMessages;
    const modifiedMessages = [...responseMessages];
    modifiedMessages[0] = {
      ...modifiedMessages[0],
      content: introduction,
    };
    saveMessage(
      apiNest,
      userId ?? userValues?.id?.toString() ?? "",
      modifiedMessages,
      window.location.href
    );
    decodeAudioAndPlay(response.audio);
  };

  const startSpeechRecognition = async (initial = false) => {
    let fixedInitial = initial;
    if (!("webkitSpeechRecognition" in window)) {
      alert(
        "Your browser does not support the Web Speech API. Please switch to Chrome or Firefox."
      );
      return;
    }

    // let silenceTimeout: NodeJS.Timeout | null = null;
    if (recognitionRef.current) {
      recognitionRef.current.stop();
      recognitionRef.current = null;
    }

    // if there is already an history of messages, we don't need to play the introduction
    if (messagesRef.current.length > 1) {
      fixedInitial = false;
    }

    setDuringCall(true);
    if (fixedInitial) {
      const [ret] = await Promise.all([
        postIntroductionMessage(apiPython, sessionId, userValues),
        playAudio(ring_3s),
      ]);

      processRunningRef.current = true;
      if (!processRunningRef.current) return;
      setProcessRunning(true);
      // await playAudio(ret.data.audio);
      await playAudio(ret.data.audio, true);
      if (!processRunningRef.current) return;
    }
    processRunningRef.current = true;
    if (!processRunningRef.current) return;
    setProcessRunning(true);

    // Now start the speech recognition
    const recognition = new webkitSpeechRecognition();
    recognition.interimResults = false;
    recognition.continuous = false;
    recognition.lang = language;
    recognition.onresult = function (event: SpeechRecognitionEvent) {
      const results = event.results;
      const finalTranscript = results[results.length - 1][0].transcript;
      sendTranscript(finalTranscript);
    };
    recognition.onerror = (event) => {
      console.log("Recognition Error: ", event.error);
      resetProcess();
    };
    recognition.start();
    recognitionRef.current = recognition;
    console.log("Speech recognition started");
    setIsListening(true);
  };

  const manualSendMessage = async () => {
    if (message.length === 0) return;
    const newMessages = [
      ...messagesRef.current,
      {
        role: "user",
        content: message,
        sessionId,
        language: language.slice(0, 2),
      },
    ];
    setMessage("");
    messagesRef.current = newMessages;
    const response = await postTextToAnswerText(
      apiPython,
      newMessages,
      userValues
    );
    messagesRef.current = [
      ...messagesRef.current,
      response.messages[response.messages.length - 1],
    ];
    const modifiedMessages = [...response.messages];
    modifiedMessages[0] = {
      ...modifiedMessages[0],
      content: introduction,
    };
    saveMessage(
      apiNest,
      userId ?? userValues?.id?.toString() ?? "",
      modifiedMessages,
      window.location.href
    );
  };

  if (loading)
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: not100vh ? "100%" : "100vh",
        }}
      >
        <Loader />
      </div>
    );

  if (userValues?.documents?.length === 0) return <MissingFields />;

  return (
    <Flex
      style={{
        border: "1px solid #D0E0FD",
        borderRadius: 10,
        width: "100%",
        height: not100vh ? "100%" : "100vh",
        overflow: "hidden",
      }}
      direction="column"
    >
      {/** HEADER CALL */}
      {!userValues?.disableCall && (
        <div style={{ justifyContent: "center", alignItems: "center" }}>
          {duringCall && (
            <Flex justify="space-around" mt={10}>
              <Avatar
                src={userValues?.chatLogoUrl ?? robot}
                size="xl"
                radius="xl"
                style={{
                  boxShadow:
                    processRunning && !isListening ? boxShadow : undefined,
                }}
              />
              <Avatar
                size="xl"
                radius="xl"
                style={{
                  boxShadow:
                    processRunning && isListening ? boxShadow : undefined,
                }}
                color="#5F3CC4"
              >
                You
              </Avatar>
            </Flex>
          )}
          <Center>
            <Button
              m={duringCall ? 10 : 20}
              radius="md"
              onClick={() =>
                !duringCall ? startSpeechRecognition(true) : resetProcess()
              }
              mx="auto"
              leftIcon={
                duringCall ? (
                  <IconPhoneOff size="1rem" />
                ) : (
                  <IconPhone size="1rem" />
                )
              }
            >
              {duringCall ? "End" : "Start"} Call
            </Button>
          </Center>
        </div>
      )}

      {/** MESSAGES */}
      <div style={{ overflowY: "auto", flex: 1, marginTop: 16 }}>
        <div style={{ marginRight: 16, marginLeft: 16 }}>
          {messagesRef.current.map((message, index) => (
            <Flex
              justify={message.role === "user" ? "flex-end" : undefined}
              key={index}
            >
              {message.role !== "user" && (
                <Avatar
                  src={userValues?.chatLogoUrl ?? robot}
                  radius="xl"
                  mr={10}
                />
              )}
              <div
                style={{
                  marginLeft: message.role !== "user" ? 0 : 50,
                  marginRight: message.role !== "user" ? 50 : 0,
                }}
              >
                <Text
                  style={{
                    color: theme.colorScheme === "dark" ? undefined : "#333333",
                    fontSize: 13,
                  }}
                >
                  {message.role !== "user" ? userValues?.chatbotName : "You"}
                </Text>
                <div
                  style={{
                    marginBottom: 16,
                    fontSize: 14,
                    backgroundColor:
                      message.role !== "user" ? "#f0f1f3" : "#7950f2",
                    padding: 10,
                    borderTopLeftRadius: message.role !== "user" ? 0 : 10,
                    borderTopRightRadius: message.role !== "user" ? 10 : 0,
                    borderBottomLeftRadius: 10,
                    borderBottomRightRadius: 10,
                    color: message.role !== "user" ? "black" : "white",
                  }}
                >
                  {message.role === "system" ? introduction : message.content}
                </div>
              </div>
            </Flex>
          ))}
          <div ref={messagesEndRef} />
        </div>
      </div>

      {/** INPUT SEND */}
      <Flex ml={16} mr={16}>
        <Textarea
          value={message}
          onChange={(value) => setMessage(value.target.value)}
          w="100%"
          onKeyDown={(e) => {
            if (e.key === "Enter" && !e.shiftKey && !duringCall) {
              e.preventDefault();
              manualSendMessage();
            }
          }}
        />
        <ActionIcon
          variant="filled"
          size="lg"
          color="blue"
          mt={16}
          ml={16}
          disabled={duringCall}
          onClick={manualSendMessage}
        >
          <IconSend size="20" />
        </ActionIcon>
      </Flex>

      {/** VOILABS ADVERTISMENT */}
      <Center>
        <Text
          size="xs"
          style={{ cursor: "pointer" }}
          onClick={() => {
            window.open("https://voilabs.com", "_blank");
          }}
        >
          Powered by{" "}
          <Text span c="blue" inherit>
            Voilabs
          </Text>
        </Text>
      </Center>
    </Flex>
  );
};
export default ChatWidget;
