import { upperFirst } from "@mantine/hooks";
import { useForm } from "@mantine/form";
import {
  TextInput,
  PasswordInput,
  // Text,
  Paper,
  Group,
  PaperProps,
  Button,
  //   Divider,
  Checkbox,
  Anchor,
  Stack,
} from "@mantine/core";
import { useApiNest } from "../useApi";
import { login, register } from "../apiRoutes";
import { UserContext } from "../UserContext";
import { useContext } from "react";
import { showNotification } from "@mantine/notifications";
import { IconX } from "@tabler/icons";
// import { GoogleButton, TwitterButton } from "../SocialButtons/SocialButtons";

export function AuthenticationForm(
  props: PaperProps & {
    state: string;
    setState: React.Dispatch<React.SetStateAction<string>>;
    close: () => void;
  }
) {
  const { state, setState, close, ...restProps } = props;
  const apiNest = useApiNest();
  const { setUser, setIsLoggedIn } = useContext(UserContext);

  const form = useForm({
    initialValues: {
      email: "",
      username: "",
      password: "",
      terms: false,
      project: window.location.href.includes("prompted") ? "prompted" : "tutor",
    },

    validate: {
      email: (val) => (/^\S+@\S+$/.test(val) ? null : "Invalid email"),
      password: (val) =>
        val.length < 8 ? "Password should include at least 8 characters" : null,
      ...(state !== "login" && {
        terms: (val) => (val ? null : "You should accept terms and conditions"),
        username: (val: string) =>
          val.length < 3 ? "Username is too short" : null,
      }),
    },
  });

  const handleFormSubmit = async (values: any) => {
    console.log("handleFormSubmit", values);
    if (props.state === "login") {
      // Handle login
      console.log("Logging in:", values);
      try {
        const user = await login(apiNest, values.email, values.password);
        localStorage.setItem("user", JSON.stringify(user));
        setUser(user);
        setIsLoggedIn(true);
        close();
      } catch (error: any) {
        console.error("Failed to login", error);
        if (error.response?.status === 401) {
          form.setFieldError("password", "Wrong password");
        }
      }
    } else if (props.state === "register") {
      // Handle registration
      console.log("Registering:", values);
      const ret: string = await register(apiNest, values);
      if (ret === "username_taken") {
        showNotification({
          message: "Login already taken",
          icon: <IconX size="1.1rem" />,
          color: "red",
        });
      } else if (ret === "email_taken") {
        showNotification({
          message: "Email already taken",
          icon: <IconX size="1.1rem" />,
          color: "red",
        });
      } else {
        // Registration successful
        console.log("Registration successful");
        const user = await login(apiNest, values.email, values.password);
        localStorage.setItem("user", JSON.stringify(user));
        setUser(user);
        setIsLoggedIn(true);
        close();
      }
    }
  };

  return (
    <Paper radius="md" {...restProps}>
      {/* <Text size="lg" weight={500}>
        Welcome to Mantine, {type} with
      </Text> */}

      {/* <Group grow mb="md" mt="md">
        <GoogleButton radius="xl">Google</GoogleButton>
        <TwitterButton radius="xl">Twitter</TwitterButton>
      </Group>

      <Divider label="Or continue with email" labelPosition="center" my="lg" /> */}

      <form onSubmit={form.onSubmit(handleFormSubmit)}>
        <Stack>
          {state === "register" && (
            <TextInput
              label="Username"
              placeholder="Your username"
              value={form.values.username}
              onChange={(event) =>
                form.setFieldValue("username", event.currentTarget.value)
              }
              error={form.errors.username && "Username is too short"}
              radius="md"
              required
            />
          )}

          <TextInput
            required
            label="Email"
            placeholder="hello@voilabs.com"
            value={form.values.email}
            onChange={(event) =>
              form.setFieldValue("email", event.currentTarget.value)
            }
            error={form.errors.email && "Invalid email"}
            radius="md"
            type="email"
          />

          <PasswordInput
            required
            label="Password"
            placeholder="Your password"
            value={form.values.password}
            onChange={(event) =>
              form.setFieldValue("password", event.currentTarget.value)
            }
            error={
              form.errors.password &&
              "Password should include at least 8 characters"
            }
            radius="md"
            type={state === "register" ? "current-password" : undefined}
          />

          {state === "register" && (
            <Checkbox
              label={
                <>
                  I accept{" "}
                  <Anchor
                    href={
                      window.location.href.includes("localhost")
                        ? "http://localhost:3000/TermsAndConditions"
                        : "https://voilabs.com/TermsAndConditions"
                    }
                    target="_blank"
                  >
                    terms and conditions
                  </Anchor>
                </>
              }
              checked={form.values.terms}
              onChange={(event) =>
                form.setFieldValue("terms", event.currentTarget.checked)
              }
              error={
                form.errors.terms && "You should accept terms and conditions"
              }
            />
          )}
        </Stack>

        <Group position="apart" mt="xl">
          {window.location.href.includes("register") && (
            <Anchor
              component="button"
              type="button"
              color="dimmed"
              onClick={() => {
                setState(state === "register" ? "login" : "register");
              }}
              size="xs"
            >
              {state === "register"
                ? "Already have an account? Login"
                : "Don't have an account? Register"}
            </Anchor>
          )}
          <Button type="submit" radius="xl">
            {upperFirst(state)}
          </Button>
        </Group>
      </form>
    </Paper>
  );
}
