import { Box, Flex, Title } from "@mantine/core";
import React from "react";

const Test = () => {
  return (
    <Box m={10}>
      <Title weight="bold">Test</Title>
      <Flex
        h={700}
        w={500}
        mt={20}
        style={{ border: "1px solid gray" }}
        direction="column"
      >
        <Box style={{ backgroundColor: "blue" }} h={100} />
        <Box style={{ backgroundColor: "green", flex: 1 }} />
        <Box style={{ backgroundColor: "red" }} h={100} />
      </Flex>
    </Box>
  );
};

export default Test;
