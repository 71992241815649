import {
  createStyles,
  // Image,
  Container,
  Title,
  Button,
  Group,
  Text,
  // List,
  // ThemeIcon,
  rem,
  Grid,
  Image,
} from "@mantine/core";
// import { IconCheck } from "@tabler/icons-react";
// import image from "./Assets/image.svg";
// import Lottie from "react-lottie";
// import animationData from "./Assets/lottie.json";
import brain from "./Assets/brain.png";
// import microphone from "./Assets/microphone.png";
import background from "./Assets/grid-bg-1.png";
import PopupForm from "../PopupForm";
import { useEffect, useState } from "react";
// import { useNavigate } from "react-router-dom";
import { throttle } from "lodash"; // Import lodash's throttle function

const useStyles = createStyles((theme) => ({
  inner: {
    display: "flex",
    justifyContent: "space-between",
    paddingTop: `calc(${theme.spacing.xl} * 4)`,
    paddingBottom: `calc(${theme.spacing.xl} * 4)`,
  },

  content: {
    maxWidth: rem(600),
    // marginRight: `calc(${theme.spacing.xl} * 8)`,

    [theme.fn.smallerThan("md")]: {
      maxWidth: "100%",
      marginRight: 0,
    },
  },

  title: {
    color: theme.colorScheme === "dark" ? theme.white : theme.black,
    fontFamily: `Inter, ${theme.fontFamily}`,
    fontSize: rem(65),
    lineHeight: 1.2,
    fontWeight: 700,

    [theme.fn.smallerThan("xs")]: {
      fontSize: rem(28),
    },
  },

  control: {
    [theme.fn.smallerThan("xs")]: {
      flex: 1,
    },
  },

  image: {
    flex: 1,

    [theme.fn.smallerThan("md")]: {
      display: "none",
    },
  },

  highlight: {
    position: "relative",
    backgroundColor: theme.fn.variant({
      variant: "light",
      color: "violet",
    }).background,
    borderRadius: theme.radius.sm,
    padding: `${rem(4)} ${rem(12)}`,
    color: "#6e90e6",
  },

  background: {
    backgroundImage: `url(${background}),radial-gradient(ellipse at right,rgba(121,58,255,0.65) 0%,rgba(255,255,255,0) 72%)!important`,
    backgroundRepeat: "repeat",
    backgroundSize: "initial",
    backgroundPosition: "center calc(0% - 1px)",
  },
}));

export function HeroBullets() {
  const { classes } = useStyles();
  // const navigate = useNavigate();
  // const defaultOptions = {
  //   loop: true,
  //   autoplay: true,
  //   animationData: animationData,
  //   rendererSettings: {
  //     preserveAspectRatio: "xMidYMid slice",
  //   },
  // };

  const [parallaxStyle, setParallaxStyle] = useState({});

  const handleScroll = () => {
    const offset = window.pageYOffset;
    setParallaxStyle({
      transform: `translateY(${offset * 0.5}px)`,
      transition: "transform 0.2s ease-out",
    });
  };

  useEffect(() => {
    const throttledHandleScroll = throttle(handleScroll, 10);
    window.addEventListener("scroll", throttledHandleScroll);
    return () => window.removeEventListener("scroll", throttledHandleScroll);
  }, []);

  return (
    <div className={classes.background}>
      <Container size="lg">
        <div className={classes.inner}>
          <Grid m={0} align="center">
            <Grid.Col xs={6}>
              <div className={classes.content}>
                <Title className={classes.title} align="left">
                  <span className={classes.highlight}>Unleash</span> the power
                  of <span className={classes.highlight}>Generative AI</span>
                </Title>
                <Text color="dimmed" mt="md" size={20} align="left">
                  Pioneering generative AI, we craft advanced solutions for
                  tomorrow's challenges, transforming industries with
                  sophisticated, forward-thinking technology.
                </Text>

                <Group mt={30}>
                  <PopupForm />
                  <Button
                    radius="xl"
                    size="md"
                    className={classes.control}
                    color="violet"
                    // onClick={() => navigate("/ai")}
                  >
                    Speak to our AI 🔒
                  </Button>
                </Group>
              </div>
            </Grid.Col>
            <Grid.Col xs={6}>
              <div style={parallaxStyle}>
                {/* <Lottie options={defaultOptions} /> */}
                {window.innerWidth >= 900 && (
                  <Image src={brain} style={{ opacity: 1 }} />
                )}
                {/* <VideoHeader /> */}
              </div>
            </Grid.Col>
          </Grid>
        </div>
      </Container>
    </div>
  );
}
