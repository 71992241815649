import React, { useState, useEffect, useRef } from "react";
import {
  ActionIcon,
  Avatar,
  Box,
  Button,
  Center,
  Flex,
  Progress,
  Switch,
  Text,
  Textarea,
  Title,
  useMantineTheme,
} from "@mantine/core";
import { IconMicrophone, IconMicrophoneOff, IconSend } from "@tabler/icons";
import { useApiNest } from "../useApi";
import { getUserData, postVoiceBotMessages } from "../apiRoutes";
import { UserDto } from "../dto/user";
import robot from "../Components/VoiLabs/Assets/robot.jpg";

const introduction = `Bonjour, je suis votre psychologue spécialisé en thérapie cognitivo-comportementale. Je suis là pour vous aider à surmonter vos difficultés. Je vous écoute.`;

const DisplayMessages = ({
  messages,
}: {
  messages: {
    role: string;
    content: string;
  }[];
}) => {
  const theme = useMantineTheme();
  const [userValues, setUserValues] = useState<UserDto>();
  const api = useApiNest();
  const messagesEndRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const fetchPostStartCall = async () => {
      const retUser = await getUserData(api);
      setUserValues(retUser.data);
    };

    fetchPostStartCall();
  }, [api]);

  // Scroll to the last message effect
  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [messages]);

  const parseBoldText = (text: string) => {
    const parts = text.split("**");

    return parts.flatMap((part, index) => {
      const splitNewLines = part.split("\n").map((linePart, lineIndex) => {
        return lineIndex !== 0 ? (
          [<br key={`br-${index}-${lineIndex}`} />, <>{linePart}</>]
        ) : (
          <>{linePart}</>
        );
      });

      if (index % 2 !== 0) {
        return [<strong key={`strong-${index}`}>{splitNewLines}</strong>];
      }
      return splitNewLines;
    });
  };

  return (
    <div
      style={{
        overflowY: "auto",
        minHeight: "500px",
        maxHeight: "calc(100vh - 48px)", // Subtract 32px from 100vh
        flex: 1,
        marginTop: 16,
      }}
    >
      <div style={{ marginRight: 16, marginLeft: 16 }}>
        {messages.map((message, index) => (
          <Flex
            justify={message.role === "user" ? "flex-end" : undefined}
            key={index}
          >
            {message.role !== "user" && (
              <Avatar
                src={userValues?.chatLogoUrl ?? robot}
                radius="xl"
                mr={10}
              />
            )}
            <div
              style={{
                marginLeft: message.role !== "user" ? 0 : 50,
                marginRight: message.role !== "user" ? 50 : 0,
              }}
            >
              <Text
                style={{
                  color: theme.colorScheme === "dark" ? undefined : "#333333",
                  fontSize: 13,
                }}
              >
                {message.role !== "user" ? userValues?.chatbotName : "You"}
              </Text>
              <div
                style={{
                  marginBottom: 16,
                  fontSize: 14,
                  backgroundColor:
                    message.role !== "user" ? "#f0f1f3" : "#7950f2",
                  padding: 10,
                  borderTopLeftRadius: message.role !== "user" ? 0 : 10,
                  borderTopRightRadius: message.role !== "user" ? 10 : 0,
                  borderBottomLeftRadius: 10,
                  borderBottomRightRadius: 10,
                  color: message.role !== "user" ? "black" : "white",
                }}
              >
                {message.role === "system"
                  ? introduction
                  : parseBoldText(message.content)}
              </div>
            </div>
          </Flex>
        ))}
        <div ref={messagesEndRef} />
      </div>
    </div>
  );
};

const Voice = () => {
  const [listening, setListening] = useState(false);
  const [automaticSend, setAutomaticSend] = useState(false);
  const api = useApiNest();
  const [editableTranscript, setEditableTranscript] = useState("");
  const [recognition, setRecognition] = useState<SpeechRecognition | null>(
    null
  );
  const [messages, setMessages] = useState([
    {
      role: "system",
      content: `Vous êtes un psychologue spécialisé en thérapie cognitivo-comportementale (TCC). Votre rôle est d'aider les utilisateurs à comprendre et à gérer leurs pensées, émotions et comportements problématiques en utilisant des techniques basées sur la TCC. Vous fournissez des réponses empathiques et professionnelles, tout en guidant les utilisateurs vers des stratégies de gestion et de résolution de problèmes. Votre approche est centrée sur le présent et orientée vers des objectifs concrets, en aidant les utilisateurs à identifier et à remettre en question les pensées et croyances dysfonctionnelles, et à développer des compétences de coping.`,
    },
  ]);
  const [userValues, setUserValues] = useState<UserDto>();
  const [progress, setProgress] = useState(0);
  const timerRef = useRef<number | null>(null);

  useEffect(() => {
    const fetchPostStartCall = async () => {
      const retUser = await getUserData(api);
      setUserValues(retUser.data);
    };
    fetchPostStartCall();
  }, [api]);

  useEffect(() => {
    if ("webkitSpeechRecognition" in window || "SpeechRecognition" in window) {
      const SpeechRecognition =
        window.SpeechRecognition || window.webkitSpeechRecognition;
      const recognitionInstance = new SpeechRecognition();
      recognitionInstance.continuous = true;
      recognitionInstance.interimResults = true;

      recognitionInstance.onresult = (event) => {
        const currentTranscript = Array.from(event.results)
          .map((result) => result[0])
          .map((result) => result.transcript)
          .join("");
        setEditableTranscript(currentTranscript);
      };

      recognitionInstance.onend = () => {
        setListening(false);
      };

      setRecognition(recognitionInstance);
    } else {
      console.warn("Speech recognition not supported in this browser.");
    }
  }, []);

  useEffect(() => {
    if (automaticSend && editableTranscript.trim()) {
      setProgress(0);
      const interval = setInterval(() => {
        setProgress((prevProgress) => {
          const nextProgress = prevProgress + 5;
          if (nextProgress >= 100) {
            clearInterval(interval);
            return 100;
          }
          return nextProgress;
        });
      }, 200);

      timerRef.current = window.setTimeout(() => {
        sendToBackend();
        setEditableTranscript("");
        setProgress(0);
      }, 4000) as unknown as number;

      return () => {
        clearInterval(interval);
        if (timerRef.current !== null) {
          clearTimeout(timerRef.current);
          timerRef.current = null;
        }
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editableTranscript, automaticSend]);

  const toggleListening = () => {
    if (recognition) {
      if (listening) {
        recognition.stop();
      } else {
        recognition.start();
      }
      setListening(!listening);
    }
  };

  const handleTextareaChange = (
    event: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    const newText = event.target.value;
    setEditableTranscript(newText);
    if (automaticSend && newText.trim()) {
      if (timerRef.current !== null) {
        clearTimeout(timerRef.current);
        timerRef.current = null;
      }
      setProgress(0);
    }
  };

  const sendToBackend = async () => {
    const newMessage = {
      role: "user",
      content: editableTranscript,
    };
    setMessages([...messages, newMessage]);

    try {
      // i still need to ... messages because in this function the messages is not updated yet
      const { messages: messages_response } = await postVoiceBotMessages(api, [
        ...messages,
        newMessage,
      ]);
      console.log("Success:", messages_response);
      setMessages(messages_response);

      if (messages_response.length > 0) {
        const lastMessage = messages_response[messages_response.length - 1];

        // Reading the last message content using SpeechSynthesis
        if ("speechSynthesis" in window) {
          const utterance = new SpeechSynthesisUtterance(lastMessage.content);
          speechSynthesis.speak(utterance);
        } else {
          console.warn("Browser does not support text-to-speech");
        }
      }
      setEditableTranscript("");
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <Flex className="responsive-flex">
      <Box m={10}>
        <Title mb={20} weight="bold" mt={20}>
          Voice Assistant
        </Title>
        <div>
          <Center>
            <Avatar
              src={userValues?.chatLogoUrl ?? robot}
              radius="xl"
              size="xl"
            />
          </Center>
          <Center m="xl">
            Click to listen:
            <ActionIcon
              size="xl"
              radius="xl"
              variant="filled"
              onClick={toggleListening}
              ml={10}
              color={!listening ? undefined : "green"}
            >
              {!listening ? <IconMicrophoneOff /> : <IconMicrophone />}
            </ActionIcon>
          </Center>
          <Center m="xl">
            <Textarea
              value={editableTranscript}
              onChange={handleTextareaChange}
              placeholder="Your transcribed text will appear here. You can edit it before sending."
              minRows={3}
              w={300}
            />
          </Center>
          {automaticSend && (
            <Center>
              <Progress value={progress} w={300} />
            </Center>
          )}
          <Center m="xl">
            <Switch
              size="lg"
              onLabel="Envoie Automatique"
              offLabel="Envoie Automatique"
              mr={20}
              onChange={(event) =>
                setAutomaticSend(event.currentTarget.checked)
              }
            />
            {!automaticSend && (
              <Button leftIcon={<IconSend />} onClick={sendToBackend}>
                Send
              </Button>
            )}
          </Center>
        </div>
      </Box>
      <div>
        <DisplayMessages messages={messages} />
      </div>
    </Flex>
  );
};

export default Voice;
