import React from "react";
import { Button, Text, TextInput, Textarea, Title } from "@mantine/core";
import { useApiPython } from "../useApi";
import { postEFKmessage } from "../apiRoutes";

const EFK = () => {
  const apiPython = useApiPython();
  const [response, setResponse] = React.useState("");
  const [subject, setSubject] = React.useState("");
  const [content, setContent] = React.useState("");
  const [infos, setInfos] = React.useState("");

  const onSubmit = async () => {
    if (!content) return alert("Veuillez remplir le contenu du mail reçu");
    const ret = await postEFKmessage(apiPython, { subject, content, infos });
    setResponse(ret);
  };
  return (
    <div style={{ margin: 10 }}>
      <Title mt={0}>Générateur de mail pour EFK</Title>
      <Text mb={30}>Dernière mise à jour: fin août</Text>

      <TextInput
        label="Sujet du mail reçu"
        placeholder="Sujet du mail reçu"
        value={subject}
        onChange={(e) => setSubject(e.currentTarget.value)}
      />
      <Textarea
        label="Email reçu"
        placeholder="Email reçu"
        withAsterisk
        styles={{ input: { height: 200 } }}
        value={content}
        onChange={(e) => setContent(e.currentTarget.value)}
      />
      <Textarea
        label="Informations supplémentaires succinctes nécessaires pour pouvoir répondre"
        placeholder="Ex : cours complet mais possible jeudi 17h / oui il y a de la place et c'est bon pour l'inscription / créneaux dispo : Mercredi 9h15, 10h15, 11h30, 13h45, 14h45 / etc..."
        value={infos}
        onChange={(e) => setInfos(e.currentTarget.value)}
      />
      <Button m={10} onClick={onSubmit}>
        Générer un mail
      </Button>
      <Text>{response}</Text>
    </div>
  );
};

export default EFK;
