import { Text, Title, useMantineTheme } from "@mantine/core";
import v from "./VoiLabs/Assets/v.png";
import oilabs from "./VoiLabs/Assets/oilabs.png";
import oilabsBlack from "./VoiLabs/Assets/oilabs-black.png";
import { UserContext } from "../UserContext";
import { useContext } from "react";

const Logo = ({ small }: { small?: boolean }) => {
  const theme = useMantineTheme();
  const { isLoggedIn, user } = useContext(UserContext);

  if (user?.project === "prompted") {
    return <Title ml={45}>Prompted</Title>;
  }

  return (
    <div style={{ display: "flex" }}>
      <img
        src={v}
        alt="VoiLabs"
        style={{
          height: small ? 35 : 55,
          marginLeft: small ? 0 : 20,
          marginTop: small ? 2 : 0,
          marginBottom: small ? 0 : 10,
          marginRight: small ? 0 : -5,
        }}
      />
      <div>
        <img
          src={theme.colorScheme === "dark" ? oilabs : oilabsBlack}
          alt="VoiLabs"
          style={{
            height: small ? 40 : 64,
            marginLeft: -13,
            marginBottom: 10,
            marginRight: -5,
            position: "relative",
          }}
        />
      </div>
      {isLoggedIn && (
        <Text
          style={{
            position: small ? undefined : "absolute",
            top: 70,
            right: 100,
            marginTop: small ? 14 : undefined,
            marginLeft: small ? 20 : 0,
          }}
        >
          STUDIO
        </Text>
      )}
    </div>
  );
};
export default Logo;
