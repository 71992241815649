import React, { createContext, useState } from "react";
import { UserDto } from "./dto/user";

// Define the shape of the context
interface UserContextProps {
  user: UserDto | null;
  setUser: React.Dispatch<React.SetStateAction<UserDto | null>>;
  isLoggedIn: Boolean | undefined;
  setIsLoggedIn: React.Dispatch<React.SetStateAction<Boolean | undefined>>;
}

// Create the context
export const UserContext = createContext<UserContextProps>({
  user: null,
  setUser: () => {},
  isLoggedIn: undefined,
  setIsLoggedIn: () => {},
});

// Create a provider component
export const UserProvider = ({ children }: { children: React.ReactNode }) => {
  const [user, setUser] = useState<UserDto | null>(null);
  const [isLoggedIn, setIsLoggedIn] = useState<Boolean | undefined>(undefined);

  return (
    <UserContext.Provider value={{ user, setUser, isLoggedIn, setIsLoggedIn }}>
      {children}
    </UserContext.Provider>
  );
};
