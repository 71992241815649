/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useEffect, useRef } from "react";
import {
  ActionIcon,
  Avatar,
  Box,
  Button,
  Center,
  Flex,
  List,
  Progress,
  ScrollArea,
  Switch,
  Text,
  Textarea,
  Title,
  useMantineTheme,
} from "@mantine/core";
import { IconMicrophone, IconMicrophoneOff, IconSend } from "@tabler/icons";
import { useApiNest, useApiPython } from "../useApi";
import {
  getUserData,
  postVoiceBotMessages,
  postVoiceBotMessagesPythonPrompted,
} from "../apiRoutes";
import { UserDto } from "../dto/user";
import profile_picture from "../Components/VoiLabs/Assets/profile_picture.jpg";
import PromptedCourse from "./PromptedCourse";

const introduction = `Bonjour, je votre assistante, je peux vous aider à générer un cours d'informatique. J'espère que vous allez bien !`;

const DisplayMessages = ({
  messages,
}: {
  messages: {
    role: string;
    content: string;
  }[];
}) => {
  const theme = useMantineTheme();
  const [userValues, setUserValues] = useState<UserDto>();
  const api = useApiNest();
  const messagesEndRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const fetchPostStartCall = async () => {
      const retUser = await getUserData(api);
      setUserValues(retUser.data);
    };

    fetchPostStartCall();
  }, [api]);

  // Scroll to the last message effect
  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [messages]);

  const parseBoldText = (text: string) => {
    const parts = text.split("**");

    return parts.flatMap((part, index) => {
      const splitNewLines = part.split("\n").map((linePart, lineIndex) => {
        return lineIndex !== 0 ? (
          [<br key={`br-${index}-${lineIndex}`} />, <>{linePart}</>]
        ) : (
          <>{linePart}</>
        );
      });

      if (index % 2 !== 0) {
        return [<strong key={`strong-${index}`}>{splitNewLines}</strong>];
      }
      return splitNewLines;
    });
  };

  return (
    <ScrollArea h={"calc(100vh - 170px)"}>
      <div
        style={{
          // overflow: "auto",
          // minHeight: "calc(100vh - 190px)",
          maxWidth: "1100px",
          // maxHeight: "calc(100vh - 48px)", // Subtract 32px from 100vh
          flex: 1,
          marginTop: 16,
        }}
      >
        <div style={{ marginRight: 16, marginLeft: 16 }}>
          {messages.map((message, index) => (
            <Flex
              justify={message.role === "user" ? "flex-end" : undefined}
              key={index}
            >
              {message.role !== "user" && (
                <Avatar
                  src={userValues?.chatLogoUrl ?? profile_picture}
                  radius="xl"
                  mr={10}
                />
              )}
              <div
                style={{
                  marginLeft: message.role !== "user" ? 0 : 50,
                  marginRight: message.role !== "user" ? 50 : 0,
                }}
              >
                <Text
                  style={{
                    color: theme.colorScheme === "dark" ? undefined : "#333333",
                    fontSize: 13,
                  }}
                >
                  {message.role !== "user" ? userValues?.chatbotName : "You"}
                </Text>
                <div
                  style={{
                    marginBottom: 16,
                    fontSize: 14,
                    backgroundColor:
                      message.role !== "user" ? "#dadada" : "#7950f2",
                    padding: 10,
                    borderTopLeftRadius: message.role !== "user" ? 0 : 10,
                    borderTopRightRadius: message.role !== "user" ? 10 : 0,
                    borderBottomLeftRadius: 10,
                    borderBottomRightRadius: 10,
                    color: message.role !== "user" ? "black" : "white",
                  }}
                >
                  {message.role === "system"
                    ? introduction
                    : parseBoldText(message.content)}
                </div>
              </div>
            </Flex>
          ))}
          <div ref={messagesEndRef} />
        </div>
      </div>
    </ScrollArea>
  );
};

const PromptedHome = () => {
  const [listening, setListening] = useState(false);
  const [automaticSend, setAutomaticSend] = useState(false);
  const api = useApiNest();
  const apiPython = useApiPython();
  const [editableTranscript, setEditableTranscript] = useState("");
  const [recognition, setRecognition] = useState<SpeechRecognition | null>(
    null
  );
  const [messages, setMessages] = useState([
    {
      role: "system",
      content: `Vous êtes un assistant.`,
    },
  ]);
  // const [userValues, setUserValues] = useState<UserDto>();
  const [progress, setProgress] = useState(0);
  const timerRef = useRef<number | null>(null);
  const [courseStructure, setCourseStructure] = useState({
    title: "Python Essentials: A Beginner's Journey to Coding",
    chapters: [
      "Getting Started with Python: Introduction to Python and Setting Up Your Environment",
      "Basics of Python: Variables, Data Types, and Operators",
      "Control Flow in Python: Conditionals and Loops",
      "Data Structures in Python: Lists, Tuples, Dictionaries, and Sets",
      "Functions and Modules: Building Reusable Code",
      "Working with Files: Reading and Writing to Files",
      "Practical Python Projects: Applying What You've Learned in Simple Projects",
    ],
  });

  // useEffect(() => {
  //   const fetchPostStartCall = async () => {
  //     const retUser = await getUserData(api);
  //     setUserValues(retUser.data);
  //   };
  //   fetchPostStartCall();
  // }, [api]);

  useEffect(() => {
    if ("webkitSpeechRecognition" in window || "SpeechRecognition" in window) {
      const SpeechRecognition =
        window.SpeechRecognition || window.webkitSpeechRecognition;
      const recognitionInstance = new SpeechRecognition();
      recognitionInstance.continuous = true;
      recognitionInstance.interimResults = true;

      recognitionInstance.onresult = (event) => {
        const currentTranscript = Array.from(event.results)
          .map((result) => result[0])
          .map((result) => result.transcript)
          .join("");
        setEditableTranscript(currentTranscript);
      };

      recognitionInstance.onend = () => {
        setListening(false);
      };

      setRecognition(recognitionInstance);
    } else {
      console.warn("Speech recognition not supported in this browser.");
    }
  }, []);

  useEffect(() => {
    if (automaticSend && editableTranscript.trim()) {
      setProgress(0);
      const interval = setInterval(() => {
        setProgress((prevProgress) => {
          const nextProgress = prevProgress + 5;
          if (nextProgress >= 100) {
            clearInterval(interval);
            return 100;
          }
          return nextProgress;
        });
      }, 200);

      timerRef.current = window.setTimeout(() => {
        sendToBackend();
        setEditableTranscript("");
        setProgress(0);
      }, 4000) as unknown as number;

      return () => {
        clearInterval(interval);
        if (timerRef.current !== null) {
          clearTimeout(timerRef.current);
          timerRef.current = null;
        }
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editableTranscript, automaticSend]);

  // const toggleListening = () => {
  //   if (recognition) {
  //     if (listening) {
  //       recognition.stop();
  //     } else {
  //       recognition.start();
  //     }
  //     setListening(!listening);
  //   }
  // };

  const handleTextareaChange = (
    event: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    const newText = event.target.value;
    setEditableTranscript(newText);
    if (automaticSend && newText.trim()) {
      if (timerRef.current !== null) {
        clearTimeout(timerRef.current);
        timerRef.current = null;
      }
      setProgress(0);
    }
  };

  const sendToBackend = async () => {
    const newMessage = {
      role: "user",
      content: editableTranscript,
    };
    setMessages([...messages, newMessage]);

    try {
      // i still need to ... messages because in this function the messages is not updated yet
      const { messages: messages_response, course_structure } =
        await postVoiceBotMessagesPythonPrompted(apiPython, [
          ...messages,
          newMessage,
        ]);

      console.log("messages_response:", messages_response);
      console.log("course_structure:", course_structure);
      setMessages(messages_response);
      if (course_structure?.title === "NONE")
        setCourseStructure({
          title: courseStructure.title,
          chapters: course_structure.chapters,
        });
      else setCourseStructure(course_structure);

      if (messages_response.length > 0) {
        const lastMessage = messages_response[messages_response.length - 1];

        // Reading the last message content using SpeechSynthesis
        if ("speechSynthesis" in window) {
          const utterance = new SpeechSynthesisUtterance(lastMessage.content);
          speechSynthesis.speak(utterance);
        } else {
          console.warn("Browser does not support text-to-speech");
        }
      }
      setEditableTranscript("");
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <Flex className="responsive-flex">
      <div style={{ width: "150%" }}>
        <Title
          m={30}
          sx={(theme) => ({
            background: "linear-gradient(45deg, #6a5acd, #ff6347)",
            WebkitBackgroundClip: "text",
            WebkitTextFillColor: "transparent",
            textShadow: "2px 2px 8px rgba(0, 0, 0, 0.2)",
            // fontFamily: "Gill Sans",
            fontWeight: 700,
            // fontSize: theme.fontSizes.xl * 1.5 + "px",
            fontSize: "2.5rem",
            textAlign: "center",
            padding: theme.spacing.xs,
          })}
        >
          {courseStructure?.title}
        </Title>
        <List m={30}>
          {courseStructure?.chapters.length > 0 &&
            courseStructure?.chapters.map((chapter, index) => (
              <Title
                mb={32}
                order={4}
                key={index}
                sx={(theme) => ({
                  "&:hover": {
                    backgroundColor:
                      theme.colorScheme === "dark"
                        ? theme.colors.dark[6]
                        : theme.colors.blue[2],
                    cursor: "pointer",
                  },
                })}
                p={5}
                style={{
                  cursor: "pointer",
                  // border: "1px solid black",
                  borderRadius: "8px",
                }}
                fw={600}
              >
                {chapter}
              </Title>
            ))}
        </List>
      </div>
      <div
        style={{
          backgroundColor: "#f0f0f0", // Light grey background color
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.6)", // Simple shadow for floating effect
          borderRadius: "10px", // Optional: rounded corners
          // overflow: "auto",
          height: "calc(100vh - 32px)",
        }}
      >
        <DisplayMessages messages={messages} />
        <Box m={10}>
          <div>
            {/* <Center m="xl">
              Click to listen:
              <ActionIcon
                size="xl"
                radius="xl"
                variant="filled"
                onClick={toggleListening}
                ml={10}
                color={!listening ? undefined : "green"}
              >
                {!listening ? <IconMicrophoneOff /> : <IconMicrophone />}
              </ActionIcon>
            </Center> */}
            <Center m="xl">
              <Textarea
                value={editableTranscript}
                onChange={handleTextareaChange}
                placeholder="Your transcribed text will appear here. You can edit it before sending."
                minRows={3}
                w="100%"
              />
              <Button leftIcon={<IconSend />} onClick={sendToBackend} m={10}>
                Send
              </Button>
            </Center>
          </div>
        </Box>
      </div>
    </Flex>
  );
};

export default PromptedHome;
