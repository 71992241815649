import { useContext, useState } from "react";
import FooterBar from "./Components/FooterBar";
import HeaderBar from "./Components/HeaderBar";
import { NavbarNested } from "./Components/NavbarNested";
import { UserContext } from "./UserContext";
import {
  AppShell,
  Burger,
  Header,
  MediaQuery,
  useMantineTheme,
} from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import Logo from "./Components/Logo";

type LayoutProps = {
  children: JSX.Element | JSX.Element[];
};

const Layout = ({ children }: LayoutProps) => {
  const { isLoggedIn } = useContext(UserContext);
  const [opened, setOpened] = useState(false);
  const theme = useMantineTheme();
  const displayHeader = useMediaQuery(`(min-width: ${theme.breakpoints.sm})`);

  if (isLoggedIn)
    return (
      <AppShell
        styles={{
          main: {
            background:
              theme.colorScheme === "dark"
                ? theme.colors.dark[8]
                : theme.colors.gray[0],
          },
        }}
        navbar={<NavbarNested opened={opened} />}
        // header={
        navbarOffsetBreakpoint="sm"
        asideOffsetBreakpoint="sm"
        // navbar={
        //   <Navbar
        //     p="md"
        //     hiddenBreakpoint="sm"
        //     hidden={!opened}
        //     width={{ sm: 200, lg: 300 }}
        //   >
        //     <Text>Application navbar</Text>
        //   </Navbar>
        // }
        header={
          !displayHeader ? (
            <Header height={{ base: 50, md: 70 }} p="md">
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  height: "100%",
                }}
              >
                <MediaQuery largerThan="sm" styles={{ display: "none" }}>
                  <Burger
                    opened={opened}
                    onClick={() => setOpened((o) => !o)}
                    size="sm"
                    color={theme.colors.gray[6]}
                    mr="xl"
                  />
                </MediaQuery>

                <Logo small />
              </div>
            </Header>
          ) : undefined
        }
      >
        {children}
      </AppShell>
    );

  return (
    <>
      <HeaderBar />
      {children}
      <FooterBar />
    </>
  );
};
export default Layout;
