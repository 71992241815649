import { Box, Title } from "@mantine/core";
import React from "react";

const TermsAndConditions = () => {
  return (
    <Box m={40}>
      <Title mb={30}>Your Data and Voilabs</Title>
      <Title order={3}>Notice & Privacy Policy</Title>
      Welcome to Voilabs! We are committed to protecting your privacy and
      respecting your data rights. This notice, alongside our Privacy Policy,
      explains our practices concerning the handling of your personal data.
      Please review it carefully.
      <Title order={3}>How Voilabs Handles Your Data</Title>
      We collect conversation details, product usage information, and your
      feedback to continually improve, develop, and provide our services and
      technologies. The lawful basis for processing your data includes your
      consent and our legitimate interest in improving our services.
      <Title order={3}>Data Subject Rights </Title>
      You have several rights under GDPR, including the right to access,
      rectify, or erase your personal data, restrict or object to processing,
      and request data portability. For exercising these rights or for any data
      protection inquiries, please contact our Data Protection Officer at [DPO’s
      contact].
      <Title order={3}>Data Storage & Management</Title>
      We store your activity and associated data securely for a predefined
      period, which you can modify in your account settings. We may share your
      personal data with trusted third parties who assist us in operating our
      services, and we ensure all transfers comply with applicable data
      protection laws.
      <Title order={3}>International Data Transfers</Title>
      We transfer personal data outside the European Economic Area under legal
      safeguards like Standard Contractual Clauses, ensuring an adequate level
      of data protection.
      <Title order={3}>Retention Periods</Title>
      We retain your personal data only as long as necessary for the purposes
      outlined in this notice and our Privacy Policy, or as required by law.
      <Title order={3}>Quality and Improvement</Title>
      Our team reviews and annotates conversations to improve our services.
      During this process, we disconnect conversations from your account,
      maintaining your privacy.
      <Title order={3}>Control Over Your Data</Title>
      You can visit your Voilabs Account to access settings and tools to
      safeguard your data and privacy. You can modify your activity settings,
      review, delete, or export your information from your Voilabs activity.
      <Title order={3}>Complaints</Title>
      If you feel that your data rights have been breached, you have the right
      to lodge a complaint with a supervisory authority in your country.
      <Title order={3}>Important Points</Title>
      Voilabs uses your interactions to provide optimized responses. Voilabs is
      evolving, and its responses may sometimes be inaccurate or not represent
      Voilabs’ views. Do not rely on Voilabs for professional advice in critical
      areas.
      <Title order={3}>Terms of Service</Title>
      By using Voilabs, you agree to our Terms of Service and any additional
      terms. Users in the EEA have the right to withdraw from these terms within
      14 days, as outlined in the EU’s Model Instructions on Withdrawal.
      <Title order={3}>Feedback</Title>
      Your feedback is vital. Please share your insights to help us refine
      Voilabs!
      <Title order={3}>Learn More</Title>
      For detailed information on our data policies and how Voilabs works,
      please refer to our Product FAQ and Privacy Help Hub.
    </Box>
  );
};

export default TermsAndConditions;
