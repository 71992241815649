/* eslint-disable react-hooks/exhaustive-deps */
import {
  ActionIcon,
  Badge,
  Box,
  Button,
  Card,
  Flex,
  Grid,
  List,
  LoadingOverlay,
  Modal,
  Text,
  TextInput,
  Textarea,
  Title,
  useMantineTheme,
} from "@mantine/core";
import React, { useState } from "react";
import {
  IconCirclePlus,
  IconFileText,
  IconTrash,
  IconUpload,
} from "@tabler/icons";
import { modals } from "@mantine/modals";
import useUser from "../Hook/useUser";
import { useDisclosure } from "@mantine/hooks";
import SavingButton from "../Components/SavingButton";

//idée : faire un format simple d'objet qui permet de créer des tables et des formulaires facilement
//pour les tables basiques, il n'y aurait pas besoin de précisions particulières
//possibilité de préciser l'affichage particulier de certains champs
//globalement renseigner le type d'input, le label
//pour les tableaux il y aurait un tableau basique et la possibilité de faire une extension
//l'objectif est de réusiliser 80% du code et de ne laisser que les particularités de la table sur cette page
//je peux faire le form ici et j'externaliserai dans un useForm tout ce qui est factorisable

export const buttonColor: Record<string, string | undefined> = {
  sync: undefined,
  saving: "gray",
  toSave: undefined,
  saved: "green",
};

const AddData = () => {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const { form, userLoading, onSubmit, setSavingState, savingState } =
    useUser();
  const theme = useMantineTheme();
  const [opened, { open, close }] = useDisclosure(false);

  return (
    <Box p={10}>
      <Modal
        opened={opened}
        onClose={close}
        title="Select a template"
        centered
        size="auto"
      >
        <Grid style={{ display: "flex" }}>
          {templates.map((template) => (
            <Grid.Col md={12} lg={6}>
              <Card
                shadow="sm"
                padding="lg"
                radius="md"
                withBorder
                style={{ cursor: "pointer" }}
                onClick={() => {
                  form.insertListItem("documents", {
                    id: 0,
                    name:
                      template.title.slice(0, -9) === "Empty"
                        ? ""
                        : template.title.slice(0, -9),
                    description: `${template.content
                      .map((point) => `${point.subtitle} ${point.text}`)
                      .join("\n\n\n")}`,
                  });
                  setSelectedIndex(form.values.documents.length);
                  close();
                  setSavingState("toSave");
                }}
              >
                <Title order={3}>{template.title}</Title>
                <List>
                  {template.content.map((point) => (
                    <List.Item>
                      <b>{point.subtitle}</b> {point.text}
                    </List.Item>
                  ))}
                </List>
              </Card>
            </Grid.Col>
          ))}
        </Grid>
      </Modal>
      <LoadingOverlay visible={userLoading} />
      <form onSubmit={form.onSubmit(onSubmit)}>
        <Box style={{ display: "flex", justifyContent: "space-between" }}>
          <Title weight="bold" mt={20}>
            Add Data
          </Title>
          <SavingButton savingState={savingState} />
        </Box>
        <Title order={2} mt={20}>
          Company Information
        </Title>
        <TextInput
          label="Company name"
          value={form.values.companyName}
          onChange={(event) => {
            form.setFieldValue("companyName", event.currentTarget.value);
            setSavingState("toSave");
          }}
          maw={400}
        />
        <TextInput
          label="Address"
          value={form.values.contactAddress}
          onChange={(event) => {
            form.setFieldValue("contactAddress", event.currentTarget.value);
            setSavingState("toSave");
          }}
          maw={400}
        />
        <Title order={2}>Contact Details</Title>
        <TextInput
          label="Contact email"
          value={form.values.contactEmail}
          onChange={(event) => {
            form.setFieldValue("contactEmail", event.currentTarget.value);
            setSavingState("toSave");
          }}
          maw={400}
        />
        <TextInput
          label="Phone"
          value={form.values.contactPhone}
          onChange={(event) => {
            form.setFieldValue("contactPhone", event.currentTarget.value);
            setSavingState("toSave");
          }}
          maw={400}
        />
        <Box
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: 20,
          }}
        >
          <Title order={2}>Documents</Title>
          <Box>
            <Button leftIcon={<IconUpload size={14} />} variant="light" mr={5}>
              Upload PDF
              <Badge color="grey" variant="light" mt={2} ml={5}>
                Not available
              </Badge>
            </Button>
          </Box>
        </Box>
        <div style={{ display: "flex", gap: 20 }}>
          <div
            onClick={() => open()}
            style={{
              height: 130,
              width: 130,
              minWidth: 130,
              backgroundColor: "white",
              border: "1px solid #E8EAEF",
              cursor: "pointer",
              margin: "20px 0 20px 0",
              borderRadius: 10,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <IconCirclePlus color="#101828" size={30} />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              overflowX: "auto",
              gap: 20,
            }}
          >
            {form.values.documents.map((doc, index) => (
              <div
                onClick={() => setSelectedIndex(index)}
                style={{
                  position: "relative",
                  height: 130,
                  width: 130,
                  minWidth: 130,
                  backgroundColor: "white",
                  border: "1px solid #E8EAEF",
                  cursor: "pointer",
                  margin: "20px 0 20px 0",
                  borderRadius: 10,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                }}
                key={index}
              >
                <IconFileText
                  color="#101828"
                  size={30}
                  style={{ marginBottom: 30 }}
                />
                <div
                  style={{
                    backgroundColor:
                      theme.colorScheme === "dark"
                        ? theme.colors.dark[4]
                        : "#FAFAFA",
                    width: "100%",
                    textAlign: "center",
                    borderTop: "1px solid #E8EAEF",
                    position: "absolute",
                    borderBottomRightRadius: 10,
                    borderBottomLeftRadius: 10,
                    bottom: 0,
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                    padding: 5,
                  }}
                >
                  {doc.name}
                </div>
              </div>
            ))}
          </div>
        </div>
        {form?.values?.documents?.length > 0 && (
          <Box>
            <Flex justify="space-between">
              <TextInput
                label="Document Name"
                value={form.values.documents[selectedIndex]?.name}
                onChange={(event) => {
                  form.setFieldValue(
                    `documents.${selectedIndex}.name`,
                    event.currentTarget.value
                  );
                  setSavingState("toSave");
                }}
                w={400}
              />
              <ActionIcon
                onClick={() =>
                  modals.openConfirmModal({
                    title: "Please confirm your action",
                    centered: true,
                    children: (
                      <Text size="sm">
                        You will also need to save the page in order for your
                        changes to become permanent.
                      </Text>
                    ),
                    labels: { confirm: "Confirm", cancel: "Cancel" },
                    onConfirm: () => {
                      form.removeListItem("documents", selectedIndex);
                      setSavingState("toSave");
                      if (selectedIndex === form.values.documents.length - 1) {
                        setSelectedIndex(selectedIndex - 1);
                      }
                    },
                  })
                }
                variant="default"
                size="lg"
                mt={27}
                ml={30}
              >
                <IconTrash size={20} />
              </ActionIcon>
            </Flex>
            <Textarea
              label="Document Content"
              value={form.values.documents[selectedIndex]?.description}
              onChange={(event) => {
                form.setFieldValue(
                  `documents.${selectedIndex}.description`,
                  event.currentTarget.value
                );
                setSavingState("toSave");
              }}
              minRows={20}
            />
          </Box>
        )}

        <div style={{ display: "flex", justifyContent: "right" }}>
          <SavingButton savingState={savingState} />
        </div>
      </form>
    </Box>
  );
};

export default AddData;

const templates = [
  { title: "Empty Template", content: [] },
  {
    title: "General Information Template",
    content: [
      {
        subtitle: "Topic/Category:",
        text: "(e.g., Company Information, Product Details, FAQ, etc.)",
      },
      {
        subtitle: "Question/Intent:",
        text: "(The user's possible question or intent)",
      },
      { subtitle: "Answer/Response:", text: "(The chatbot's response)" },
      {
        subtitle: "Keywords/Phrases:",
        text: "(Specific words or phrases related to the question that might help in identifying the intent)",
      },
      {
        subtitle: "Related Links/Resources:",
        text: "(Links to web pages or documents, if any, for more detailed information)",
      },
      {
        subtitle: "Follow-up Questions:",
        text: "(Possible questions that users might ask after getting the initial answer)",
      },
      { subtitle: "Notes:", text: "(Any additional context or clarification)" },
    ],
  },
  {
    title: "Procedure/Instruction Template",
    content: [
      {
        subtitle: "Procedure Title:",
        text: "(e.g., 'How to reset password?', 'Steps to install software', etc.)",
      },
      {
        subtitle: "Step-by-step Instructions:",
        text: "(Break down the process in easy steps)",
      },
      {
        subtitle: "Required Materials/Tools:",
        text: "(If any materials or tools are needed for the procedure)",
      },
      {
        subtitle: "Expected Outcome:",
        text: "(What should happen after following the steps)",
      },
      {
        subtitle: "Common Issues & Troubleshooting:",
        text: "(List out common problems users might face and how to resolve them)",
      },
      {
        subtitle: "Visuals:",
        text: "(Images, diagrams, or GIFs to illustrate steps, if available)",
      },
      {
        subtitle: "Keywords/Phrases:",
        text: "(To aid in the retrieval of the procedure)",
      },
      { subtitle: "Notes:", text: "(Any additional context or clarification)" },
    ],
  },
  {
    title: "Product/Service Information Template",
    content: [
      { subtitle: "Product/Service Name:", text: "" },
      {
        subtitle: "Features & Benefits:",
        text: "(List down features with short descriptions)",
      },
      { subtitle: "Pricing:", text: "(If applicable)" },
      {
        subtitle: "Availability:",
        text: "(Where and how can users avail of the product or service?)",
      },
      {
        subtitle: "Usage Instructions:",
        text: "(How to use the product or service? This might refer to the Procedure/Instruction Template)",
      },
      {
        subtitle: "Reviews/Testimonials:",
        text: "(Short snippets of user reviews or feedback, if any)",
      },
      {
        subtitle: "Related Products/Services:",
        text: "(Might help in upselling or cross-selling)",
      },
      {
        subtitle: "Keywords/Phrases:",
        text: "(To aid in the retrieval of the product/service information)",
      },
      { subtitle: "Notes:", text: "(Any other relevant details)" },
    ],
  },
];
