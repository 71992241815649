import { Box, Text } from "@mantine/core";
import React from "react";

const MissingFields = () => {
  return (
    <Box>
      <Text>
        To activate the chatbot, you must first add at least one document. These
        documents serve as the chatbot's knowledge base.
      </Text>
      <Text>You can find it in the Add Data section.</Text>
    </Box>
  );
};

export default MissingFields;
