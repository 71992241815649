import { createStyles, Anchor, Group, ActionIcon, rem } from "@mantine/core";
import {
  IconBrandDiscord,
  // IconBrandTwitter,
  // IconBrandYoutube,
  // IconBrandInstagram,
  IconBrandLinkedin,
  // IconBrandGithub,
} from "@tabler/icons";
// import Logo from "./Logo";

const useStyles = createStyles((theme) => ({
  footer: {
    marginTop: rem(120),
    borderTop: `${rem(1)} solid ${
      theme.colorScheme === "dark" ? theme.colors.dark[5] : theme.colors.gray[2]
    }`,
  },

  inner: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: `${theme.spacing.md} ${theme.spacing.md}`,

    [theme.fn.smallerThan("sm")]: {
      flexDirection: "column",
    },
  },

  links: {
    [theme.fn.smallerThan("sm")]: {
      marginTop: theme.spacing.lg,
      marginBottom: theme.spacing.sm,
    },
  },
}));

// interface FooterBarProps {
//   links: { link: string; label: string }[];
// }

const links = [
  // { link: "#", label: "Profil Malt" },
  { link: "#", label: "Copyright © 2024" },
  // { link: "#", label: "Blog" },
];

export default function FooterBar(/*{ links }: FooterBarProps*/) {
  const { classes } = useStyles();
  const items = links.map((link) => (
    <Anchor<"a">
      color="dimmed"
      key={link.label}
      href={link.link}
      sx={{ lineHeight: 1 }}
      onClick={(event) => event.preventDefault()}
      size="sm"
    >
      {link.label}
    </Anchor>
  ));

  return (
    <div className={classes.footer}>
      <div className={classes.inner}>
        <Group className={classes.links}>{items}</Group>

        <Group spacing="xs" position="right" noWrap>
          <ActionIcon
            size="lg"
            variant="default"
            radius="xl"
            onClick={() =>
              window.open("https://discord.gg/cnfXKNwnRd", "_blank")
            }
          >
            <IconBrandDiscord size={18} stroke={1.5} />
          </ActionIcon>
          <ActionIcon
            size="lg"
            variant="default"
            radius="xl"
            onClick={() =>
              window.open(
                "https://www.linkedin.com/company/voilabs-ai/",
                "_blank"
              )
            }
          >
            <IconBrandLinkedin size={18} stroke={1.5} />
          </ActionIcon>
        </Group>
      </div>
    </div>
  );
}
