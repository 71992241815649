import { Button } from "@mantine/core";
import React from "react";
import { buttonColor } from "../Pages/AddData";
import { SavingStateType } from "../Hook/useUser";

const SavingButton = ({ savingState }: { savingState: SavingStateType }) => {
  return (
    <Button
      type="submit"
      color={buttonColor[savingState]}
      disabled={savingState === "sync"}
      mt={16}
    >
      {savingState === "sync" ? "Saved" : "Saving"}
    </Button>
  );
};

export default SavingButton;
