import { useState, useEffect, useRef } from "react";
import { useApiNest } from "../useApi";
import { getUserData, postUserData } from "../apiRoutes";
import { useForm } from "@mantine/form";

export type SavingStateType = "sync" | "toSave" | "saving" | "saved";

function useUser() {
  const [user, setUser] = useState<any>();
  const [userLoading, setUserLoading] = useState(true);
  const apiNest = useApiNest();
  const [savingState, setSavingState] = useState<SavingStateType>("sync");
  const saveTimeoutRef = useRef<null | NodeJS.Timeout>(null);

  const form = useForm({
    initialValues: {
      id: 0,
      chatLogoUrl: "",
      mainColor: "",
      buttonColor: "",
      companyName: "",
      introduction: "",
      contactEmail: "",
      contactPhone: "",
      contactAddress: "",
      chatbotName: "",
      chatbotVoice: "female",
      friendly: 1,
      humorous: 1,
      empathic: 1,
      professional: 1,
      chatbotThemeLight: true,
      disableCall: true,
      botAskPhoneContact: false,
      botAskEmailContact: false,
      sendConversationEmail: true,
      colorSchemeLight: false,
      documents: [{ id: 0, name: "", description: "" }],
      examples: [{ id: 0, content: "" }],
      exactPhrases: [{ id: 0, phrase: "" }],
    },
    validate: {
      chatbotName: (value) => (value.length < 1 ? "Required" : null),
      introduction: (value) => (value.length < 1 ? "Required" : null),
      companyName: (value) => (value.length < 1 ? "Required" : null),
    },
  });
  const formValuesRef = useRef(form.values);

  const getData = async () => {
    try {
      const ret = await getUserData(apiNest);
      setUser(ret.data);
      form.setValues(ret.data);
    } finally {
      setUserLoading(false);
    }
  };

  const onSubmit = async () => {
    try {
      console.log("onSubmit", form.values);
      setSavingState("saving");
      await postUserData(apiNest, form.values);
      await getData();
      setSavingState("saved");
      setTimeout(() => setSavingState("sync"), 2000);
    } catch (error) {
      console.error("Error while saving form data:", error);
    }
  };

  useEffect(() => {
    formValuesRef.current = form.values;

    const save = async () => {
      if (savingState === "toSave") {
        await postUserData(apiNest, formValuesRef.current);
        setSavingState("saved");
        setTimeout(() => setSavingState("sync"), 2000);
      }
    };

    if (savingState === "toSave") {
      // If savingState changes before the 2 seconds, clear the timeout
      if (saveTimeoutRef.current) {
        clearTimeout(saveTimeoutRef.current);
      }

      // Set the timeout for the saving function
      saveTimeoutRef.current = setTimeout(() => {
        save();
      }, 2000);
    }

    // Cleanup on unmount
    return () => {
      if (saveTimeoutRef.current) {
        clearTimeout(saveTimeoutRef.current);
      }
    };
  }, [savingState, form.values, apiNest]);

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    user,
    userLoading,
    form,
    getData,
    onSubmit,
    savingState,
    setSavingState,
  };
}

export default useUser;
