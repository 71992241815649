import { Center, Stack, Title } from "@mantine/core";
import React from "react";

const StudioHome = () => {
  return (
    <Center>
      <Stack mt={40} dir="column">
        <Title mt={10} mb={10} size={55} align="center">
          Welcome to Voilabs Studio!
        </Title>
        {/* <Box style={{ width: "100%", maxWidth: 840, margin: "auto" }}>
          <div
            style={{
              position: "relative",
              paddingBottom: "56.25%",
              height: 0,
              overflow: "hidden",
            }}
          >
            <iframe
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
              }}
              src="https://www.youtube-nocookie.com/embed/COos64ZrDk8?si=HSDP-fGR7El_PIJH?autoplay=1&mute=1"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowFullScreen
            ></iframe>
          </div>
        </Box> */}
      </Stack>
    </Center>
  );
};

export default StudioHome;
