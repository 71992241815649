import React, { useContext, useEffect } from "react";
import "./App.css";
import { UserContext } from "./UserContext";
import Router from "./router";
import {
  MantineProvider,
} from "@mantine/core";
import { UserDto } from "./dto/user";
import { useApiNest } from "./useApi";
import { getMe } from "./apiRoutes";
import { ModalsProvider } from "@mantine/modals";
import { Notifications } from "@mantine/notifications";

// merge at some point the app and router and index.tsx pages into 2 pages

function App() {
  const localStorageOk =
    window.location.href.includes("://voilabs.com") ||
    window.location.href.includes("http://localhost:");
  const { setUser, setIsLoggedIn, isLoggedIn, user } = useContext(UserContext);
  const apiNest = useApiNest();

  useEffect(() => {
    const fetchUser = async () => {
      console.log("window.location.href", window.location.href, localStorageOk);
      if (!localStorageOk) {
        setUser(null);
        setIsLoggedIn(false);
        return;
      }
      const user = JSON.parse(localStorage.getItem("user") as string);
      if (!user || !user.accessToken || !user.expiresAt) {
        setUser(null);
        setIsLoggedIn(false);
        return;
      }

      const isUserLoggedIn = Math.floor(Date.now() / 1000) <= user.expiresAt;
      setIsLoggedIn(isUserLoggedIn);
      if (!isUserLoggedIn) {
        setUser(null);
        return;
      }

      try {
        const user: UserDto = await getMe(apiNest);
        setUser(user);
        setIsLoggedIn(true);
        console.log("User is login");
      } catch (error) {
        setUser(null);
        console.log("User is not login");
      }
    };

    fetchUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isLoggedIn === undefined) return null;

  return (
    <div className="App">
      <MantineProvider
        theme={{
          fontFamily: "Inter, sans-serif",
          colorScheme: user?.colorSchemeLight ? "light" : "dark",
          colors: {
            dark: [
              "#d5d7e0",
              "#acaebf",
              "#8c8fa3",
              "#666980",
              "#4d4f66",
              "#34354a",
              "#2b2c3d",
              "#100022", // changed to purple from 1d1e30
              "#0c0d21",
              "#01010a",
            ],
            blue: [
              "#F3F0FF",
              "#E5DBFF",
              "#D0BFFF",
              "#B197FC",
              "#9775FA",
              "#845EF7",
              "#7950F2",
              "#7048E8",
              "#6741D9",
              "#5F3DC4",
            ],
          },
          headings: {
            sizes: {
              h1: { fontWeight: 400 },
              h2: { fontWeight: 400 },
            },
          },
          components: {
            Modal: {
              styles: () => ({
                title: { fontSize: 22 },
              }),
            },
          },
        }}
        withGlobalStyles
        withNormalizeCSS
      >
        <Notifications />
        <ModalsProvider>
          <Router />
        </ModalsProvider>
      </MantineProvider>
    </div>
  );
}

export default App;
