import React, { useEffect, useState } from "react";
import emailjs from "emailjs-com";
import {
  Button,
  Center,
  Modal,
  TextInput,
  Textarea,
  Title,
  createStyles,
} from "@mantine/core";
import { useViewportSize } from "@mantine/hooks";

const useStyles = createStyles((theme) => ({
  control: {
    [theme.fn.smallerThan("xs")]: {
      flex: 1,
    },
  },
}));

const PopupForm = ({
  opened,
  close,
  backgroundColor,
}: {
  opened?: boolean;
  close?: () => void;
  backgroundColor?: any;
}) => {
  const { classes } = useStyles();
  const [displayPopup, setDisplayPopup] = useState(false);
  const { width } = useViewportSize();

  useEffect(() => {
    if (opened) {
      setDisplayPopup(true);
    }
  }, [opened]);

  function sendEmail(e: any) {
    e.preventDefault();
    console.log("sendEmail");

    emailjs
      .sendForm(
        "service_jv4q1og",
        "template_qk0b0gm",
        e.target,
        "aSkh5gVJTUbJ7aTZv"
      )
      .then(
        (result) => {
          console.log(result.text);
          setDisplayPopup(false);
          if (close) close();
        },
        (error) => {
          console.log(error.text);
        }
      );
  }

  function ContactUs() {
    return (
      <Modal
        opened={displayPopup}
        onClose={() => {
          setDisplayPopup(false);
          if (close) close();
        }}
        centered
        size="47rem"
        radius={20}
        padding="xl"
      >
        {width > 900 ? (
          <Title mb={20} order={1} size="3rem">
            Ready to lead the revolution
            <br />
            AI-powered?
          </Title>
        ) : (
          <Title mb={20} order={2}>
            Ready to lead the revolution AI-powered?
          </Title>
        )}
        <Title mb={20} order={3}>
          We will get back to as soon as possible
        </Title>
        <form className="contact-form" onSubmit={sendEmail}>
          <Center display={"block"}>
            <TextInput
              label="Name"
              type="text"
              name="user_name"
              required
              size="md"
            />
            <TextInput
              label="Email"
              type="email"
              name="user_email"
              required
              size="md"
              mt={10}
            />
            <TextInput
              label="Company Name (optional)"
              type="text"
              name="user_company"
              size="md"
              mt={10}
            />
            <Textarea
              label="Message (optional)"
              name="user_role"
              size="md"
              mt={10}
            />
          </Center>
          <Center>
            <Button
              type="submit"
              radius="xl"
              size="md"
              className={classes.control}
              color="violet"
              mt={20}
            >
              Get in Touch
            </Button>
          </Center>
        </form>
      </Modal>
    );
  }

  return (
    <>
      <ContactUs />
      <Button
        variant="outline"
        radius="xl"
        size="md"
        className={classes.control}
        m={10}
        onClick={() => setDisplayPopup(true)}
        style={{ backgroundColor }}
      >
        Get in Touch
      </Button>
    </>
  );
};

export default PopupForm;
