import axios from "axios";
import { useContext } from "react";
import { UserContext } from "./UserContext";
import { isPublicRoute } from "./apiRoutes";

let baseUrlPython;
let baseUrlNest;

if (process.env.NODE_ENV === "production") {
  baseUrlPython = "https://api.voilabs.com";
} else {
  baseUrlPython = "http://localhost:8000";
  // baseUrlPython = "https://api.voilabs.com";
}

if (process.env.NODE_ENV === "production") {
  baseUrlNest = "https://back.voilabs.com";
} else {
  baseUrlNest = "http://localhost:8080";
  // baseUrl = "https://api.voilabs.com";
}

export const backendUrlPython = baseUrlPython;
export const backendUrlNest = baseUrlNest;

const apiPython = axios.create({
  baseURL: baseUrlPython, // Replace with your backend API's base URL
  withCredentials: false, // Send cookies with requests
});

const apiNest = axios.create({
  baseURL: baseUrlNest,
  withCredentials: false,
});

export function useApiPython() {
  // const { setUser } = useContext(UserContext);

  // api.interceptors.request.use((config) => {
  //   const user = JSON.parse(localStorage.getItem("user") || "{}");
  //   if (user && user.accessToken) {
  //     config.headers["Authorization"] = `Bearer ${user.accessToken}`;
  //   }
  //   return config;
  // });

  apiPython.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response && error.response.status === 401) {
        // If a request is unauthorized, clear the user's local authentication data
        // localStorage.removeItem("user");
        // setUser(null);
      }
      return Promise.reject(error);
    }
  );

  return apiPython;
}

export function useApiNest() {
  const { setUser, user } = useContext(UserContext);

  apiNest.interceptors.request.use((config) => {
    if (!isPublicRoute(config.url ?? "", config.method ?? "")) {
      const user = JSON.parse(localStorage.getItem("user") || "{}");
      if (user && user.accessToken) {
        config.headers["Authorization"] = `Bearer ${user.accessToken}`;
      }
    }
    return config;
  });

  apiNest.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (!isPublicRoute(error.config.url, error.config.method)) {
        if (error.response && error.response.status === 401 && user) {
          localStorage.removeItem("user");
          setUser(null);
          window.location.reload();
        }
      }
      return Promise.reject(error);
    }
  );

  return apiNest;
}
