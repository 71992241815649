import {
  ThemeIcon,
  Text,
  Title,
  Container,
  SimpleGrid,
  createStyles,
} from "@mantine/core";
import { IconArrowUpRight, IconBoxModel, IconNetwork } from "@tabler/icons";
import { IconUser, IconDatabase, IconSitemap } from "@tabler/icons-react";

export const MOCKDATA = [
  {
    icon: IconNetwork,
    title: "Advanced Data Analytics",
    description:
      "Employs sophisticated data analysis tools to extract actionable insights, aiding in decision-making and uncovering new business opportunities.",
  },
  {
    icon: IconUser,
    title: "Expert Consultation and Support",
    description:
      "Provides expert guidance on AI integration, offering continuous support and advice to ensure optimal performance and adaptation to new trends.",
  },
  // {
  //   icon: IconStackPush,
  //   title: "Consistency",
  //   description:
  //     "Delivers uniformly excellent service with pre-loaded FAQs, ensuring every customer has the same high-quality experience.",
  // },
  // {
  //   icon: IconMoneybag,
  //   title: "Cost-effective",
  //   description:
  //     "Decreases operational costs by reducing the need for large customer service teams, without sacrificing customer satisfaction.",
  // },
  {
    icon: IconDatabase,
    title: "Efficient Process Automation",
    description:
      "Streamlines business processes through automation, increasing efficiency and accuracy while reducing time and resource expenditure.",
  },
  {
    icon: IconArrowUpRight,
    title: "Scalable AI Frameworks",
    description:
      "Provides adaptable AI solutions designed to grow with your business, ensuring seamless scalability for increasing demands.",
  },
  {
    icon: IconBoxModel,
    title: "Cutting-edge Technology Adoption",
    description:
      "Maintains a leading edge in AI technology, offering clients advanced, competitive solutions.",
  },
  {
    icon: IconSitemap,
    title: "Customized AI Solutions",
    description:
      "Tailors unique AI strategies to meet specific business needs, ensuring each solution is perfectly aligned with your company's goals and challenges.",
  },
  // {
  //   icon: IconLock,
  //   title: "Security and Privacy",
  //   description:
  //     "Prioritizes data safety, maintaining high-level security standards for all customer interactions.",
  // },
  // {
  //   icon: IconNetwork,
  //   title: "Scalability",
  //   description:
  //     "Adapts effortlessly with your business growth, catering to an increasing number of customer inquiries without compromising on response time or quality.",
  // },
  // {
  //   icon: IconUser,
  //   title: "24/7 Availability",
  //   description:
  //     "Always present to assist your customers, offering non-stop support even during peak hours and holidays.",
  // },
  // {
  //   icon: IconMessage2,
  //   title: "Consistency",
  //   description:
  //     "Delivers uniformly excellent service with pre-loaded FAQs, ensuring every customer has the same high-quality experience.",
  // },
  // {
  //   icon: IconMoneybag,
  //   title: "Cost-effective",
  //   description:
  //     "Decreases operational costs by reducing the need for large customer service teams, without sacrificing customer satisfaction.",
  // },
  // {
  //   icon: IconDatabase,
  //   title: "Data Collection and Analysis",
  //   description:
  //     "Leverages customer interactions to gather valuable insights about customer preferences, habits and satisfaction levels.",
  // },
  // {
  //   icon: IconClock,
  //   title: "Routine Task Handling",
  //   description:
  //     "Automatically takes care of repetitive queries and tasks, freeing up your human team to tackle complex issues.",
  // },
  // {
  //   icon: IconLanguage,
  //   title: "Language Barriers Broken",
  //   description:
  //     "Our AI understands and interacts in multiple languages, ensuring clear communication with customers worldwide.",
  // },
  // {
  //   icon: IconSitemap,
  //   title: "Integration Ease",
  //   description:
  //     "Seamlessly blends with your existing systems for quick setup and immediate service improvement.",
  // },
  // {
  //   icon: IconLock,
  //   title: "Security and Privacy",
  //   description:
  //     "Prioritizes data safety, maintaining high-level security standards for all customer interactions.",
  // },
];

interface FeatureProps {
  icon: React.FC<any>;
  title: React.ReactNode;
  description: React.ReactNode;
}

export function Feature({ icon: Icon, title, description }: FeatureProps) {
  return (
    <div>
      <ThemeIcon variant="light" size={40} radius={40} color="violet">
        <Icon size="1.1rem" stroke={1.5} />
      </ThemeIcon>
      <Text mt="sm" mb={7}>
        {title}
      </Text>
      <Text size="sm" color="dimmed" sx={{ lineHeight: 1.6 }}>
        {description}
      </Text>
    </div>
  );
}

const useStyles = createStyles((theme) => ({
  wrapper: {
    paddingTop: `calc(${theme.spacing.xl} * 4)`,
    paddingBottom: `calc(${theme.spacing.xl} * 4)`,
  },

  title: {
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,
    fontWeight: 900,
    marginBottom: theme.spacing.md,
    textAlign: "center",

    [theme.fn.smallerThan("sm")]: {
      fontSize: 28,
      textAlign: "left",
    },
  },

  description: {
    textAlign: "center",

    [theme.fn.smallerThan("sm")]: {
      textAlign: "left",
    },
  },
}));

interface FeaturesGridProps {
  title?: React.ReactNode;
  description?: React.ReactNode;
  data?: FeatureProps[];
}

export function FeatureVoiLabs({
  title = "",
  description = "",
  data = MOCKDATA,
}: FeaturesGridProps) {
  const { classes } = useStyles();
  const features = data.map((feature, index) => (
    <Feature {...feature} key={index} />
  ));

  return (
    <Container className={classes.wrapper}>
      <Title className={classes.title}>{title}</Title>

      <Container size={560} p={0}>
        <Text size="sm" className={classes.description}>
          {description}
        </Text>
      </Container>

      <SimpleGrid
        mt={60}
        cols={3}
        spacing={50}
        breakpoints={[
          { maxWidth: 980, cols: 2, spacing: "xl" },
          { maxWidth: 755, cols: 1, spacing: "xl" },
        ]}
      >
        {features}
      </SimpleGrid>
    </Container>
  );
}
