import { Box, Button, Modal, TextInput, Textarea, Title } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import React from "react";

const ChapiterModal = () => {
  return (
    <>
      <TextInput
        label="Chapiter Name"
        placeholder="empty for default"
        // value={form.values.chatLogoUrl}
        // onChange={(event) => {
        //   form.setFieldValue("chatLogoUrl", event.currentTarget.value);
        //   setSavingState("toSave");
        // }}
        maw={500}
        mb={5}
      />
      <Textarea
        label="Chapiter Content"
        description="Up to 10 000 characters"
        // value={form.values.introduction}
        // onChange={(event) => {
        //   form.setFieldValue("introduction", event.currentTarget.value);
        //   setSavingState("toSave");
        // }}
        maw={500}
        mb={5}
        minRows={8}
      />
      <Button mt={20} mb={20} onClick={undefined}>
        Add a new chapiter
      </Button>
    </>
  );
};

const Tutor = () => {
  const [opened, { open, close }] = useDisclosure(false);

  return (
    <Box p={10}>
      <Modal opened={opened} onClose={close} title="Add a new chapiter">
        <ChapiterModal />
      </Modal>
      <Title weight="bold" mt={20}>
        Datacenter
      </Title>
      <Title order={4}>Store your chapiters</Title>
      <Button mt={20} mb={20} onClick={open}>
        Add a new chapiter
      </Button>
    </Box>
  );
};

export default Tutor;
