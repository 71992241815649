import {
  Card,
  Image,
  Text,
  Badge,
  Button,
  Group,
  useMantineTheme,
} from "@mantine/core";

const ProductCard = ({
  title,
  text,
  image,
  open,
}: {
  title: string;
  text: string;
  image: string;
  open: () => void;
}) => {
  const theme = useMantineTheme();
  return (
    <Card
      shadow="sm"
      padding="lg"
      radius="md"
      withBorder
      style={{
        border: `solid 1px ${theme.colors.violet[7]}`,
        backgroundColor: '#110022',
      }}
    >
      <Card.Section>
        <Image src={image} height={160} alt="" />
      </Card.Section>

      <Group position="apart" mt="md" mb="xs">
        <Text weight={500}>{title}</Text>
        <Badge color="violet" variant="light">
          Live
        </Badge>
      </Group>

      <Text size="sm" color="dimmed">
        {text}
      </Text>

      <Button
        variant="light"
        color="blue"
        fullWidth
        mt="md"
        radius="md"
        onClick={open}
      >
        Request a Demo
      </Button>
    </Card>
  );
};

export default ProductCard;
