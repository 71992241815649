import {
  Avatar,
  Box,
  Center,
  Container,
  Flex,
  Text,
  Title,
  useMantineTheme,
} from "@mantine/core";
import React, { useContext, useEffect } from "react";
import { MessageDto } from "../dto/user";
import { useApiNest } from "../useApi";
import { getMessages } from "../apiRoutes";
import robot from "../Components/VoiLabs/Assets/robot.jpg";
import moment from "moment";
import { UserContext } from "../UserContext";
import { useParams } from "react-router-dom";

const Inbox = () => {
  const [conversations, setConversations] = React.useState<
    { messages: MessageDto[]; id: number; createdAt: Date }[]
  >([]);
  const [selectedConversation, setSelectedConversation] = React.useState<
    MessageDto[]
  >([]);
  const { sessionId } = useParams<{ sessionId: string }>();
  const { user } = useContext(UserContext);
  const apiNest = useApiNest();
  const theme = useMantineTheme();

  useEffect(() => {
    const getData = async () => {
      const ret = await getMessages(apiNest);
      const transformetData = (
        ret.data as {
          conversation: string;
          id: number;
          createdAt: Date;
          sessionId: string;
        }[]
      ).map((c) => {
        return {
          messages: JSON.parse(c.conversation) as any as MessageDto[],
          id: c.id,
          createdAt: c.createdAt,
          sessionId: c.sessionId,
        };
      });
      setConversations(transformetData.reverse());
      if (sessionId) {
        const selected = transformetData.find((c) => c.sessionId === sessionId);
        if (selected) {
          setSelectedConversation(selected.messages);
        }
      }
    };
    getData();
  }, [apiNest, sessionId]);

  return (
    <Box m={10}>
      <Title mb={20} weight="bold" mt={20}>
        Inbox
      </Title>
      <Flex>
        <Box w="66">
          <table>
            <thead>
              <tr>
                <th style={{ minWidth: 50 }}>Id</th>
                <th>Date</th>
                <th>Number of messages</th>
              </tr>
            </thead>
            <tbody>
              {conversations.map((conversation) => (
                <tr
                  key={conversation.id}
                  style={{ cursor: "pointer" }}
                  onClick={() => setSelectedConversation(conversation.messages)}
                >
                  <td>{conversation.id}</td>
                  <td>
                    {moment(conversation.createdAt).format(
                      "MMMM Do YYYY, h:mm:ss a"
                    )}
                  </td>
                  <td>
                    <Center>{conversation.messages?.length}</Center>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </Box>
        <Container
          size="lg"
          style={{
            display: "flex",
            flexDirection: "column",
            height: 610,
            justifyContent: "center",
            border: "1px solid #D0E0FD",
            borderRadius: 10,
            width: 400,
          }}
        >
          <div style={{ overflowY: "auto", height: 580 }}>
            {selectedConversation.length === 0 && (
              <Center mt={250}>
                <Text size={18}>
                  Click on a conversation
                  <br />
                  to display the messages
                </Text>
              </Center>
            )}
            {selectedConversation.map((message, index) => (
              <Flex
                justify={message.role === "user" ? "flex-end" : undefined}
                key={index}
              >
                {message.role !== "user" && (
                  <Avatar
                    src={user?.chatLogoUrl ?? robot}
                    radius="xl"
                    mr={10}
                  />
                )}
                <div
                  style={{
                    marginLeft: message.role !== "user" ? 0 : 50,
                    marginRight: message.role !== "user" ? 50 : 0,
                  }}
                >
                  <Text
                    style={{
                      color:
                        theme.colorScheme === "dark" ? undefined : "#333333",
                      fontSize: 13,
                    }}
                  >
                    {message.role !== "user" ? user?.chatbotName : "You"}
                  </Text>
                  <div
                    style={{
                      marginBottom: 16,
                      fontSize: 14,
                      backgroundColor:
                        message.role !== "user" ? "#f0f1f3" : "#7950f2",
                      padding: 10,
                      borderTopLeftRadius: message.role !== "user" ? 0 : 10,
                      borderTopRightRadius: message.role !== "user" ? 10 : 0,
                      borderBottomLeftRadius: 10,
                      borderBottomRightRadius: 10,
                      color: message.role !== "user" ? "black" : "white",
                    }}
                  >
                    {message.content}
                  </div>
                </div>
              </Flex>
            ))}
          </div>
        </Container>
      </Flex>
    </Box>
  );
};

export default Inbox;
