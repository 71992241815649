import {
  Box,
  Button,
  Center,
  LoadingOverlay,
  Modal,
  PasswordInput,
  Switch,
  Text,
  Title,
  useMantineTheme,
} from "@mantine/core";
import React, { useContext } from "react";
import useUser from "../Hook/useUser";
import SavingButton from "../Components/SavingButton";
import { useForm } from "@mantine/form";
import { cancelSubscription, postNewPassword } from "../apiRoutes";
import { useApiNest } from "../useApi";
import { showNotification } from "@mantine/notifications";
import { IconCheck, IconMoonStars, IconSun } from "@tabler/icons";
import { UserContext } from "../UserContext";
import { useDisclosure } from "@mantine/hooks";
import axios from "axios";

// Example CV text content (replace with the actual CV content)
const cv_text_content = `Ahmath GADJI
A la recherche d'un CDI dans le
domaine de l'IT
Informations
personnelles
ahmathgadji27@gmail.com
+33678938104
Ile-De-France,
91300 Massy
Sénégalais
linkedin.com/in/ahmath-gadji-
292a01195
Compétences
Python (Spacy & Nltk,
Pytorch, Transformers,
Sklearn, Pandas)
Statistiques appliquées,
Time series, Computer
Vision, Machine Learning,
Deep Learning et NLP
R
SQL & NoSQL
Java
Langues
Wolof
Français
Anglais (TOEIC: 820)
Développeur Python | Data scientist
Spécialiste du langage Python et de la data science, je recherche un poste de
développeur Python en CDI avec de gros challenges dans la data. Autonome
avec une grande capacité d'adaptation et des compétences transverses, je suis
constamment en quête de connaissance pour rester à la pointe des technologies
émergentes dans le domaine de la data, ce qui me permet de garantir la qualité
des résultats et de proposer des solutions créatives et innovantes.
Expériences professionnelles
IA for African Languages de nov. 2022 à ce jour
Projet Personnel, Massy
Un projet personnel sur l'IA pour les langues africaines, particulièrement ma
langue maternelle le Wolof qui est très peu dotées.
Développeur Python | Data scientist R&D de avr. 2022 à oct. 2022
EDF R&D, Palaiseau
Mission: Développement de modèles d'IA pour le traitement des données de
consommation d'électricité des compteurs Linky afin d'enrichir la connaissance
client.Technologies utilisées: Docker, Lunix, Python (Sklearn, Pytorch,
Tensorflow, Pandas, Jupyter Notebook)
Développeur Python | Data scientist de janv. 2021 à août 2021
INSERM (Institut national de la santé et de la recherche médicale), Châtillon
Mission: Élaboration d'algorithmes de NLP pour l'extraction d'informations
critiques (effets secondaires des médicaments) dans les comptes rendus
hospitaliers de patients atteints de cancer. Technologies utilisées: Python
(Jupyter Notebook, Sklearn, Pytorch, Tensorflow, Pandas, Spacy & Nltk)
Développeur Python | Data scientist de déc. 2020 à juin 2021
AP-HP (Assistance publique - Hôpitaux de Paris), Evry
Mission : Etude d'une méthode de diagnostic de la maladie d'Alzheimer basée
sur des algorithmes d'IA.
Data Miner de juin 2020 à août 2020
Hôpital Antoine Béclère, Châtillon
Traitement statistique de données médicales d'une plateforme d'intermédiation (
Sipilink) entre médecins traitants et professionnels hospitaliers. Technologies
utilisées: Langage R, RMarkdown, Github
Formation
Master en Data Science de sept. 2021 à avr. 2022
Ecole Polytechnique X, Palaiseau
Double diplôme master 2 en mathématiques appliquées statistiques pour la
science des données.
Ecole d'Ingénieur de sept. 2019 à sept. 2022
Telecom Sudparis, Evry
Formation généraliste dans le domaine du numérique: Informatique,
Développement web et d'applications, Réseaux informatiques, Sécurité, Maths
appliquées et autres
Classes préparatoires scientifiques MP* de sept. 2016 à juin 2019
Lycée du Parc, Lyon
Formation : Mathématiques, Physiques, Informatique et Science de l'ingénieur
Activités extra-professionnelles
Prof en programmation Python. de janv. 2022 à ce jour
IAS (institut des algorithmes du Sénégal), France
Dispensation de formation certifiante en ligne d'introduction au langage Python.`;

const Settings = () => {
  const { user, form, userLoading, onSubmit, setSavingState, savingState } =
    useUser();
  const { setUser } = useContext(UserContext);
  const [opened, { open, close }] = useDisclosure(false);

  const apiNest = useApiNest();
  const theme = useMantineTheme();

  const formPassword = useForm({
    initialValues: {
      currentPassword: "",
      newPassword: "",
      newPasswordConfirmation: "",
    },
    validate: {
      currentPassword: (val) => (val ? null : "Required"),
      newPassword: (val) => (val ? null : "Required"),
      newPasswordConfirmation: (val) => (val ? null : "Required"),
    },
  });

  const submitNewPassword = async () => {
    if (
      formPassword.values.newPassword !==
      formPassword.values.newPasswordConfirmation
    ) {
      formPassword.setErrors({
        newPassword: "Passwords don't match",
        newPasswordConfirmation: "Passwords don't match",
      });
      return;
    }
    try {
      await postNewPassword(
        apiNest,
        formPassword.values.currentPassword,
        formPassword.values.newPassword
      );
    } catch (e) {
      formPassword.setErrors({
        currentPassword: "Wrong password",
      });
      return;
    }
    console.log("password updated");
    showNotification({
      message: "Password updated successfully",
      icon: <IconCheck size="1.1rem" />,
      color: "green",
    });
    // formPassword.reset();
  };

  return (
    <Box m={10}>
      <LoadingOverlay visible={userLoading} />
      <Title weight="bold" mt={20}>
        Settings
      </Title>
      <form onSubmit={form.onSubmit(onSubmit)}>
        <Switch
          label="Send an email when a new conversation is started"
          checked={form.values.sendConversationEmail}
          onChange={(event) => {
            form.setFieldValue(
              "sendConversationEmail",
              event.currentTarget.checked
            );
            setSavingState("toSave");
          }}
          mt={15}
          mb={5}
        />
        <Switch
          mt={10}
          mb={4}
          label="Studio theme (light/dark)"
          // labelPosition="left"
          color={theme.colorScheme === "dark" ? "gray" : "dark"}
          onLabel={
            <IconSun size="1rem" stroke={2.5} color={theme.colors.yellow[4]} />
          }
          offLabel={
            <IconMoonStars
              size="1rem"
              stroke={2.5}
              color={theme.colors.blue[6]}
            />
          }
          checked={form.values.colorSchemeLight}
          onChange={(event) => {
            form.setFieldValue("colorSchemeLight", event.currentTarget.checked);
            setSavingState("toSave");
            setUser((u) => ({
              ...u,
              colorSchemeLight: event.currentTarget.checked,
            }));
          }}
        />
        <SavingButton savingState={savingState} />
      </form>
      {user?.subscription !== "free" && (
        <Button mt={20} onClick={open}>
          Cancel Subscription
        </Button>
      )}
      <Modal opened={opened} onClose={close} title="Cancel your subscription">
        <Text>
          Click this button to confirm the cancellation of your subscription:
        </Text>
        <Center>
          <Button
            onClick={async () => {
              console.log("cancel subscription");
              await cancelSubscription(apiNest);
              window.location.reload();
            }}
            mt={20}
          >
            Cancel Subscription
          </Button>
        </Center>
      </Modal>
      <Title mt={20} order={2}>
        Change Password
      </Title>
      <form onSubmit={formPassword.onSubmit(submitNewPassword)}>
        <PasswordInput
          maw={400}
          label="Current password"
          {...formPassword.getInputProps("currentPassword")}
        />
        <PasswordInput
          maw={400}
          label="New password"
          {...formPassword.getInputProps("newPassword")}
        />
        <PasswordInput
          maw={400}
          label="New password confirmation"
          {...formPassword.getInputProps("newPasswordConfirmation")}
        />
        <Box maw={400} mt={20}>
          <Button type="submit">Update password</Button>
        </Box>
      </form>
      <Button
        onClick={async () => {
          const ret = await axios.post("http://localhost:8000/cv-json", {
            cv_text_content,
          });
          console.log("ret", ret.data.responseContent);
        }}
        mt={30}
        color="orange"
      >
        Chaine test
      </Button>
    </Box>
  );
};

export default Settings;
