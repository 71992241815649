import { Center, Loader, Title } from "@mantine/core";
import React from "react";
import { useLocation } from "react-router-dom";
import { useApiNest } from "../useApi";
import { verifyPayment } from "../apiRoutes";

const Success = () => {
  const location = useLocation();
  const api = useApiNest();
  const [state, setState] = React.useState("loading");

  React.useEffect(() => {
    const queryString = location.search;
    const urlParams = new URLSearchParams(queryString);
    const sessionId = urlParams.get("session_id");

    const verifyPaymentSession = async () => {
      if (sessionId) {
        try {
          const response = await verifyPayment(api, sessionId);
          console.log("Verification response:", response.data);
          if (response.data.success) {
            setState("success");
          }
        } catch (error) {
          console.error("Verification error:", error);
          // Handle error, e.g., show an error message
        }
      }
    };

    verifyPaymentSession();
  }, [location, api]);

  return (
    <Center>
      <Title mb={10} weight="bold" mt={120}>
        {state === "success" ? (
          <>
            Success 🎉
            <br />
            Thank You!
          </>
        ) : (
          <Loader />
        )}
      </Title>
    </Center>
  );
};

export default Success;
