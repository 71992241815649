import {
  Button,
  Flex,
  List,
  Paper,
  Text,
  ThemeIcon,
  Title,
  useMantineTheme,
} from "@mantine/core";
import { IconCircleCheck } from "@tabler/icons";
import React from "react";
import { postSubscribe } from "../apiRoutes";
import { useApiNest } from "../useApi";
import useUser from "../Hook/useUser";

const content = [
  {
    title: "Free Version",
    price: 0,
    priceId: "",
    features: ["Feature 1", "Feature 2", "Feature 3"],
  },
  {
    title: "€29/month",
    price: 29,
    priceId:
      process.env.NODE_ENV === "production"
        ? "price_1OiyLzBY1m1Her53ewYBhubl" // production subscription
        : "price_1OiyYIBY1m1Her5368p01qzs", // test subscription
    features: ["Feature 1", "Feature 2", "Feature 3"],
  },
  //   {
  //     title: "€89/month",
  //     price: 89,
  //     priceId: "price_1OiyMNBY1m1Her53vV6ytIV4",
  //     features: ["Feature 1", "Feature 2", "Feature 3"],
  //   },
];

const Pricing = () => {
  const theme = useMantineTheme();
  const api = useApiNest();
  const { user } = useUser();

  const subscribe = async (priceId: string) => {
    try {
      const ret = await postSubscribe(api, priceId);
      if (ret.url) {
        window.location.href = ret.url;
      } else {
        console.log("Failed to get a valid session URL from the backend.");
      }
    } catch (error) {
      console.error("Error subscribing:", error);
    }
  };

  return (
    <div style={{ textAlign: "center" }}>
      <Title mb={10} weight="bold" mt={20}>
        Plans {process.env.NODE_ENV}
      </Title>
      <Title order={4}>No hidden fees. Cancel anytime.</Title>
      <Flex style={{ justifyContent: "center" }} wrap="wrap">
        {content.map((elem, i) => (
          <Paper
            m={15}
            p={25}
            radius={15}
            style={{
              backgroundColor:
                theme.colorScheme === "dark" ? "#2B2C3D" : undefined,
            }}
            key={i}
          >
            <Title weight="bold" mt={20}>
              {elem.title}
            </Title>
            <Button
              m={25}
              disabled={elem.price === 0 || user?.subscription !== "free"}
              onClick={() => subscribe(elem.priceId)}
            >
              {elem.price === 0
                ? "Included"
                : user?.subscription !== "free"
                ? "Already Subscribed!"
                : "Get Started"}
            </Button>
            <Text c="dimmed">Secured by Stripe</Text>
            <List
              spacing="xs"
              size="sm"
              icon={
                <ThemeIcon color="teal" size={24} radius="xl">
                  <IconCircleCheck size="1rem" />
                </ThemeIcon>
              }
              style={{ textAlign: "left" }}
              mt={25}
              mb={25}
            >
              <List.Item>Clone or download repository</List.Item>
              <List.Item>Install dependencies with yarn</List.Item>
              <List.Item>To start development server run npm</List.Item>
              <List.Item>30+ Customizable exerciess</List.Item>
            </List>
          </Paper>
        ))}
      </Flex>
    </div>
  );
};

export default Pricing;
