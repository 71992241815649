/* eslint-disable react-hooks/exhaustive-deps */
import {
  ActionIcon,
  Badge,
  Box,
  Button,
  // ColorInput,
  Flex,
  Grid,
  LoadingOverlay,
  Select,
  Slider,
  Switch,
  Text,
  TextInput,
  Textarea,
  Title,
} from "@mantine/core";
import React from "react";
import { IconTrash } from "@tabler/icons";
import useUser from "../Hook/useUser";
import SavingButton from "../Components/SavingButton";
// import ButtonColorInput from "../Components/ButtonColorInput";

const MARKS = [
  { value: 0, label: "0" },
  { value: 1, label: "1" },
  { value: 2, label: "2" },
  { value: 3, label: "3" },
];

const Customize = () => {
  const { form, userLoading, onSubmit, setSavingState, savingState } =
    useUser();

  return (
    <Box p={10}>
      <LoadingOverlay visible={userLoading} />
      <form onSubmit={form.onSubmit(onSubmit)}>
        <Box style={{ display: "flex", justifyContent: "space-between" }}>
          <Title weight="bold" mt={20}>
            Customize
          </Title>
          <SavingButton savingState={savingState} />
        </Box>
        <TextInput
          label="Agent Name"
          {...form.getInputProps("chatbotName")}
          onChange={(event) => {
            form.setFieldValue("chatbotName", event.currentTarget.value);
            setSavingState("toSave");
          }}
          maw={400}
          mb={5}
        />
        {/* <ColorInput
          label="Widget Color"
          description="Required to update the integration code on your website to change color"
          {...form.getInputProps("mainColor")}
          value={form.values.mainColor}
          onChange={(value: string) => {
            form.setFieldValue("mainColor", value);
            setSavingState("toSave");
          }}
          maw={400}
        /> */}
        {/* <Switch
          mt={10}
          mb={4}
          label="Chatbot Theme (light/dark)"
          labelPosition="left"
          color={theme.colorScheme === "dark" ? "gray" : "dark"}
          onLabel={
            <IconSun size="1rem" stroke={2.5} color={theme.colors.yellow[4]} />
          }
          offLabel={
            <IconMoonStars
              size="1rem"
              stroke={2.5}
              color={theme.colors.blue[6]}
            />
          }
          value={form.values.chatbotTheme ? "on" : "off"}
          onChange={(event) => {
            form.setFieldValue("chatbotTheme", event.currentTarget.checked);
            setSavingState("toSave");
          }}
        /> */}
        <TextInput
          label="Agent Profile Picture Url"
          placeholder="empty for default"
          value={form.values.chatLogoUrl}
          onChange={(event) => {
            form.setFieldValue("chatLogoUrl", event.currentTarget.value);
            setSavingState("toSave");
          }}
          maw={400}
          mb={5}
        />
        <Textarea
          label="Welcome Message"
          description="This message will be displayed when the user opens the chat widget"
          value={form.values.introduction}
          onChange={(event) => {
            form.setFieldValue("introduction", event.currentTarget.value);
            setSavingState("toSave");
          }}
          maw={400}
          mb={5}
        />
        <Switch
          label="Ask customer phone contact"
          checked={form.values.botAskPhoneContact}
          onChange={(event) => {
            form.setFieldValue(
              "botAskPhoneContact",
              event.currentTarget.checked
            );
            setSavingState("toSave");
          }}
          mt={15}
          mb={5}
        />
        <Switch
          label="Ask customer email address"
          checked={form.values.botAskEmailContact}
          onChange={(event) => {
            form.setFieldValue(
              "botAskEmailContact",
              event.currentTarget.checked
            );
            setSavingState("toSave");
          }}
          mt={15}
          mb={5}
        />
        <Title mt={20} order={2}>
          Voice{" "}
          <Badge color="grey" variant="light">
            Not available for beta
          </Badge>
        </Title>
        <Select
          label="Agent Voice"
          value={form.values.chatbotVoice}
          data={[
            { value: "female", label: "Female" },
            { value: "male", label: "Male" },
          ]}
          onChange={(value: string) => {
            form.setFieldValue("chatbotVoice", value);
            setSavingState("toSave");
          }}
          disabled
          maw={400}
          mb={5}
        />
        <Title mt={20} order={2}>
          Tone
        </Title>
        <Grid style={{ display: "flex" }}>
          <Grid.Col md={12} lg={6}>
            <Text>Friendly</Text>
            <Slider
              marks={MARKS}
              min={0}
              max={3}
              step={1}
              precision={1}
              mb={25}
              value={form.values.friendly}
              onChange={(value: number) => {
                form.setFieldValue("friendly", value);
                setSavingState("toSave");
              }}
            />
          </Grid.Col>
          <Grid.Col md={12} lg={6}>
            <Text>Humorous</Text>
            <Slider
              marks={MARKS}
              min={0}
              max={3}
              step={1}
              precision={1}
              mb={25}
              value={form.values.humorous}
              onChange={(value: number) => {
                form.setFieldValue("humorous", value);
                setSavingState("toSave");
              }}
            />
          </Grid.Col>
          <Grid.Col md={12} lg={6}>
            <Text>Empathic</Text>
            <Slider
              marks={MARKS}
              min={0}
              max={3}
              step={1}
              precision={1}
              mb={25}
              value={form.values.empathic}
              onChange={(value: number) => {
                form.setFieldValue("empathic", value);
                setSavingState("toSave");
              }}
            />
          </Grid.Col>
          <Grid.Col md={12} lg={6}>
            <Text>Professional</Text>
            <Slider
              marks={MARKS}
              min={0}
              max={3}
              step={1}
              precision={1}
              mb={25}
              value={form.values.professional}
              onChange={(value: number) => {
                form.setFieldValue("professional", value);
                setSavingState("toSave");
              }}
            />
          </Grid.Col>
        </Grid>

        <Title mt={20} order={2}>
          Sample Scripts
        </Title>
        {form.values.examples &&
          form.values.examples.map((example, index) => (
            <Box key={index}>
              <Flex mt={10} justify="space-between" align="flex-end">
                <Text>Sample Script {index + 1}</Text>
                <ActionIcon
                  onClick={() => {
                    form.removeListItem("examples", index);
                    setSavingState("toSave");
                  }}
                  variant="default"
                  mb={5}
                >
                  <IconTrash size={20} />
                </ActionIcon>
              </Flex>
              <Textarea
                value={example.content}
                onChange={(event) => {
                  form.setFieldValue(
                    `examples.${index}.content`,
                    event.currentTarget.value
                  );
                  setSavingState("toSave");
                }}
              />
            </Box>
          ))}
        <Button
          onClick={() => {
            form.insertListItem("examples", {
              id: 0,
              content: "",
            });
            setSavingState("toSave");
          }}
          mt={10}
          variant="light"
        >
          Add
        </Button>

        <div style={{ display: "flex", justifyContent: "right" }}>
          <SavingButton savingState={savingState} />
        </div>
      </form>
    </Box>
  );
};

export default Customize;
