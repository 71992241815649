import React from "react";
//@ts-ignore
import video_bg from "./VoiLabs/Assets/hero_header_compressed.mp4";

const VideoHeader = () => {
  return (
    <div
      style={{
        // position: "fixed",
        // top: 0,
        // left: 0,
        // width: "100vw",
        height: "calc(100vh - 69px)",
        overflow: "hidden",
        zIndex: -1,
      }}
    >
      <video
        autoPlay
        loop
        muted
        style={{ width: "100%", height: "100%", objectFit: "cover" }}
      >
        <source src={video_bg} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    </div>
  );
};

export default VideoHeader;
