import {
  Box,
  Button,
  Center,
  Container,
  Grid,
  Image,
  Paper,
  Text,
  Title,
  createStyles,
  rem,
  useMantineTheme,
} from "@mantine/core";
// import { CTA } from "../Components/HeroText";
import { HeroBullets } from "../Components/VoiLabs/HeroBullets";
import { FeatureVoiLabs } from "../Components/VoiLabs/Assets/FeatureVoiLabs";
// import { useNavigate } from "react-router-dom";
import PopupForm from "../Components/PopupForm";
import { useEffect, useRef, useState } from "react";
import { useDisclosure } from "@mantine/hooks";
import { LoginRegisterModal } from "../Components/HeaderBar";
import head from "../Components/VoiLabs/Assets/head.png";
import { throttle } from "lodash";
import { Carousel } from "@mantine/carousel";
import ProductCard from "../Components/ProductCard";
import aiCourse from "../Components/VoiLabs/Assets/ai_course.png";
import chatbot from "../Components/VoiLabs/Assets/chatbot.png";
import voicePsy from "../Components/VoiLabs/Assets/voice_psy.png";
import cv from "../Components/VoiLabs/Assets/cv.png";
import search from "../Components/VoiLabs/Assets/search.jpg";

const useStyles = createStyles((theme) => ({
  control: {
    [theme.fn.smallerThan("xs")]: {
      flex: 1,
    },
  },

  highlight: {
    position: "relative",
    // backgroundColor: theme.fn.variant({
    //   variant: "light",
    //   color: "violet",
    // }).background,
    borderRadius: theme.radius.sm,
    // padding: `${rem(4)} ${rem(12)}`,
    color: "#6e90e6",

    // color: theme.colorScheme === "dark" ? theme.white : theme.black,
    fontFamily: `Inter, ${theme.fontFamily}`,
    fontSize: rem(55),
    lineHeight: 1.2,
    fontWeight: 700,

    [theme.fn.smallerThan("xs")]: {
      fontSize: rem(28),
    },
  },
}));

const carouselItems = [
  {
    title: "AI Course Generation",
    text: "Elevate learning experiences with our AI-generated courses, tailored for engaging and effective educational journeys.",
    image: aiCourse,
  },
  {
    title: "AI Voice Chatbot",
    text: "Revolutionize your customer interactions with our advanced AI-powered chatbot, delivering instant, reliable 24/7 support and engagement.",
    image: chatbot,
  },
  {
    title: "AI Chatbot for Mental Health",
    text: "Experience compassionate, AI-driven mental health support with Voice Chat Psy, offering confidential guidance anytime, anywhere.",
    image: voicePsy,
  },
  {
    title: "CV Standardization Tool",
    text: "Streamline CV formatting for consistency and professionalism. Ideal for businesses standardizing resumes to a uniform style.",
    image: cv,
  },
  {
    title: "Multimodal Search Engine",
    text: "A comprehensive vectorial search engine designed for seamless querying across diverse content types and formats.",
    image: search,
  },
];

const VoiLabs = () => {
  const { classes } = useStyles();
  const [opened, { close }] = useDisclosure(false);
  const [openedPopup, { open, close: closePopup }] = useDisclosure(false);
  const [state, setState] = useState("login");
  // const navigate = useNavigate();
  const [parallaxStyle, setParallaxStyle] = useState({});
  const theme = useMantineTheme();
  const imageRef = useRef(null); // Create a ref for the image

  // Define the offset value to start the parallax effect sooner
  const parallaxOffset = 300; // Adjust this value as needed

  const handleScroll = () => {
    const offset = window.pageYOffset;
    if (imageRef.current) {
      const imageTop =
        (imageRef.current as HTMLDivElement)?.getBoundingClientRect().top +
        offset -
        parallaxOffset; // Subtract the offset value
      if (offset > imageTop) {
        setParallaxStyle({
          transform: `translateY(${(offset - imageTop) * 0.5}px)`,
          transition: "transform 0.2s ease-out",
        });
      } else {
        setParallaxStyle({});
      }
    }
  };

  useEffect(() => {
    const throttledHandleScroll = throttle(handleScroll, 10);
    window.addEventListener("scroll", throttledHandleScroll);
    return () => window.removeEventListener("scroll", throttledHandleScroll);
  }, []);

  return (
    <Box style={{ textAlign: "center" }}>
      <LoginRegisterModal
        opened={opened}
        close={close}
        state={state}
        setState={setState}
      />
      <HeroBullets />

      <Container size="lg">
        <Grid m={0} mt={50} align="center" mb={70}>
          <Grid.Col xs={12} md={6}>
            {/* <Image maw={340} mx="auto" radius="md" src={work} alt="work" /> */}
            <div style={parallaxStyle} ref={imageRef}>
              {/* <Lottie options={defaultOptions} /> */}
              <Image src={head} style={{ opacity: 1 }} />
              {/* <VideoHeader /> */}
            </div>
          </Grid.Col>
          <Grid.Col xs={12} md={6}>
            <Text
              style={{
                textAlign: "left",
                fontSize: 18,
                border: `1px solid ${theme.colors.violet[7]}`,
                padding: 20,
                borderRadius: 16,
                // marginTop: 40,

                backgroundColor: theme.fn.variant({
                  variant: "light",
                  color: "violet",
                }).background,
              }}
            >
              <Title
                style={{
                  justifyContent: "left",
                  marginBottom: 10,
                  fontWeight: 700,
                  zIndex: 1,
                  position: "relative",
                }}
                order={3}
              >
                <span className={classes.highlight}>
                  Need a Quick AI Solution?
                </span>
                <br />
                Discover Feasibility with a Proof-of-Concept (POC) in Just 1-4
                Weeks!
              </Title>
              <Text color="dimmed">
                Post-POC, our expert team will rapidly transition your validated
                concept into a full-scale, custom AI solution, tailored for any
                platform.
              </Text>
              <div style={{ height: 10 }} />
              <PopupForm backgroundColor="#110022" />
            </Text>
          </Grid.Col>
        </Grid>
        {window.innerWidth < 900 ? (
          <>
            <Title
              style={{
                justifyContent: "left",
                marginBottom: 20,
                fontWeight: 700,
                zIndex: 1,
                position: "relative",
              }}
            >
              Our Realisations
            </Title>
            <Carousel
              // className="carouselContainer"
              slideSize="70%"
              slideGap="md"
              controlSize={30}
              loop
              withIndicators
              mr={1}
            >
              {carouselItems.map((i) => (
                <Carousel.Slide key={i.title}>
                  <ProductCard
                    title={i.title}
                    text={i.text}
                    image={i.image}
                    open={open}
                  />
                </Carousel.Slide>
              ))}
            </Carousel>
          </>
        ) : (
          <Center>
            <Grid style={{ maxWidth: 950, justifyContent: "center" }}>
              <Grid.Col>
                <Title
                  style={{
                    justifyContent: "left",
                    marginBottom: 20,
                    fontWeight: 700,
                    zIndex: 1,
                    position: "relative",
                  }}
                >
                  Our Realisations
                </Title>
                <Carousel
                  // className="carouselContainer"
                  withIndicators
                  slideSize="33.333333%"
                  slideGap="md"
                  loop
                  align="start"
                  slidesToScroll={3}
                >
                  {carouselItems.map((i) => (
                    <Carousel.Slide key={i.title}>
                      <ProductCard
                        title={i.title}
                        text={i.text}
                        image={i.image}
                        open={open}
                      />
                    </Carousel.Slide>
                  ))}
                </Carousel>
              </Grid.Col>
            </Grid>
          </Center>
        )}

        <FeatureVoiLabs />

        <Paper m={10}>
          <PopupForm opened={openedPopup} close={closePopup} />
        </Paper>
        <Button
          radius="xl"
          size="md"
          className={classes.control}
          color="violet"
          // onClick={() => navigate("/ai")}
          m={10}
        >
          Speak to our AI 🔒
        </Button>
      </Container>
    </Box>
  );
};
export default VoiLabs;
