import { Button, Title, createStyles, rem } from "@mantine/core";
import React, { useState } from "react";
import { LoginRegisterModal } from "../Components/HeaderBar";
import { useDisclosure } from "@mantine/hooks";

const useStyles = createStyles((theme) => ({
  title: {
    color: theme.colorScheme === "dark" ? theme.white : theme.black,
    fontFamily: `Inter, ${theme.fontFamily}`,
    fontSize: rem(65),
    lineHeight: 1.2,
    fontWeight: 700,

    [theme.fn.smallerThan("xs")]: {
      fontSize: rem(28),
    },
  },
  highlight: {
    position: "relative",
    backgroundColor: theme.fn.variant({
      variant: "light",
      color: "violet",
    }).background,
    borderRadius: theme.radius.sm,
    padding: `${rem(4)} ${rem(12)}`,
    color: "#6e90e6",
  },
}));

const TutorLanding = () => {
  const { classes } = useStyles();
  const [opened, { open, close }] = useDisclosure(false);
  const [state, setState] = useState("login");

  return (
    <div>
      <Title className={classes.title} align="center" mt={100}>
        <span className={classes.highlight}>Unleash</span> the power of a{" "}
        <span className={classes.highlight}>Personalized Tutor</span>
      </Title>
      <LoginRegisterModal
        opened={opened}
        close={close}
        state={state}
        setState={setState}
      />
      <Button
        onClick={() => {
          setState("register");
          open();
        }}
        style={{ display: "block", margin: "auto", marginTop: 50 }}
        size="xl" 
      >
        Try for free
      </Button>
      <img
        src="https://miro.medium.com/v2/resize:fit:1024/1*o7D9tsb2MVokChEMsECr7g.png"
        alt="Tutor landing"
        width={400}
        height={400}
        style={{ display: "block", margin: "auto", marginTop: 50 }}
      />
    </div>
  );
};

export default TutorLanding;
