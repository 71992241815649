import {
  Badge,
  Box,
  Button,
  Card,
  Center,
  Flex,
  Group,
  Modal,
  Text,
  Title,
} from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { Prism } from "@mantine/prism";
import {
  IconBrandInstagram,
  IconBrandMessenger,
  IconBrandWhatsapp,
  IconMessageChatbot,
} from "@tabler/icons";
import React, { useContext } from "react";
import { UserContext } from "../UserContext";
import useUser from "../Hook/useUser";
import MissingFields from "../Components/MissingFields";

const Integrate = () => {
  const [opened, { open, close }] = useDisclosure(false);
  const { user } = useContext(UserContext);
  const { form } = useUser();

  const myCodeBody = `<script src="https://voilabs.com/widget.js" type="text/javascript" id="${user?.id}" async defer></script>`;

  if (form?.values.documents?.length === 0) {
    return (
      <Box m={10}>
        <Title mb={10} weight="bold" mt={20}>
          Integrate
        </Title>
        <MissingFields />
      </Box>
    );
  }

  return (
    <div style={{ margin: 10 }}>
      <Modal
        opened={opened}
        onClose={close}
        title="Copy paste this block of code into your website index.html file body"
        centered
        size="auto"
      >
        <Prism language="markup" styles={{ scrollArea: { height: 100 } }}>
          {myCodeBody}
        </Prism>
      </Modal>
      <Title mb={10} weight="bold" mt={20}>
        Integrate
      </Title>
      <Center style={{ display: "flex", flexDirection: "column" }}>
        <Card shadow="sm" padding="lg" radius="md" withBorder w={700} m={10}>
          <Group position="apart" mt="xs" mb="xs">
            <Title order={2} weight={500}>
              Link to Public URL
            </Title>
            <Prism
              language="markup"
              copyLabel="Copy to clipboard"
            >{`https://voilabs.com/widget/${user?.id}`}</Prism>
          </Group>

          <Button
            variant="light"
            color="blue"
            fullWidth
            mt="md"
            radius="md"
            onClick={() => {
              const newWindow = window.open(
                `https://voilabs.com/widget/${user?.id}`,
                "_blank",
                "noopener,noreferrer"
              );
              if (newWindow) newWindow.opener = null;
            }}
          >
            Go to Public URL
          </Button>
        </Card>
        <Flex>
          <Card
            shadow="sm"
            padding="lg"
            radius="md"
            withBorder
            maw={340}
            m={10}
          >
            <Card.Section>
              <Center>
                <IconMessageChatbot size={120} color="#7950F2" />
              </Center>
            </Card.Section>

            <Group position="apart" mt="md" mb="xs">
              <Title order={2} weight={500}>
                Web Widget
              </Title>
              <Badge color="green" variant="light">
                Beta
              </Badge>
            </Group>

            <Text size="sm" color="dimmed">
              Enhance your website's functionality by integrating our widget,
              which can also be integrated into a WordPress website. It's a
              seamless process designed to elevate user experience, providing
              additional features that are both engaging and user-friendly.
            </Text>

            <Button
              variant="light"
              color="blue"
              fullWidth
              mt="md"
              radius="md"
              onClick={open}
            >
              Use this integration
            </Button>
          </Card>
          <Card
            shadow="sm"
            padding="lg"
            radius="md"
            withBorder
            maw={340}
            m={10}
          >
            <Card.Section>
              <Center>
                <IconBrandWhatsapp size={120} color="#7950F2" />
              </Center>
            </Card.Section>

            <Group position="apart" mt="md" mb="xs">
              <Title order={2} weight={500}>
                WhatsApp
              </Title>
              <Badge color="gray" variant="light">
                Soon
              </Badge>
            </Group>

            <Text size="sm" color="dimmed">
              Improve your connectivity by integrating our widget on WhatsApp.
              It’s designed for smoother interactions, offering additional
              features that enrich your communication experience effortlessly.
            </Text>
            {/* 
            <Button variant="light" color="blue" fullWidth mt="md" radius="md">
              Use this integration
            </Button> */}
          </Card>
        </Flex>
        <Flex>
          <Card
            shadow="sm"
            padding="lg"
            radius="md"
            withBorder
            maw={340}
            m={10}
          >
            <Card.Section>
              <Center>
                <IconBrandInstagram size={120} color="#7950F2" />
              </Center>
            </Card.Section>

            <Group position="apart" mt="md" mb="xs">
              <Title order={2} weight={500}>
                Instagram
              </Title>
              <Badge color="gray" variant="light">
                Soon
              </Badge>
            </Group>

            <Text size="sm" color="dimmed">
              Integrate our widget on Instagram to unlock interactive chatbot
              conversations. It's designed to provide immediate responses,
              ensuring a dynamic, engaging user experience with every
              interaction.
            </Text>

            {/* <Button variant="light" color="blue" fullWidth mt="md" radius="md">
              Use this integration
            </Button> */}
          </Card>
          <Card
            shadow="sm"
            padding="lg"
            radius="md"
            withBorder
            maw={340}
            m={10}
          >
            <Card.Section>
              <Center>
                <IconBrandMessenger size={120} color="#7950F2" />
              </Center>
            </Card.Section>

            <Group position="apart" mt="md" mb="xs">
              <Title order={2} weight={500}>
                Messenger
              </Title>
              <Badge color="gray" variant="light">
                Soon
              </Badge>
            </Group>

            <Text size="sm" color="dimmed">
              Enhance your Messenger chats by integrating our widget. It's
              engineered to provide an enriched interaction experience, offering
              additional functionalities that are intuitive and user-centric.
            </Text>

            {/* <Button variant="light" color="gray" fullWidth mt="md" radius="md">
              Use this integration
            </Button> */}
          </Card>
        </Flex>
      </Center>
    </div>
  );
};

export default Integrate;
