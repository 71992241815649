import { Box, Center, Title } from "@mantine/core";
import React from "react";
import ChatWidget from "../Components/Widjet/ChatWidget";

const Preview = () => {
  return (
    <Box m={10}>
      <Title weight="bold" mt={20}>
        Preview
      </Title>
      <Center mt={20}>
        <Box h={610} w={400}>
          <ChatWidget not100vh />
        </Box>
      </Center>
    </Box>
  );
};

export default Preview;
