import {
  Navbar,
  Group,
  // Code,
  ScrollArea,
  createStyles,
  rem,
  Menu,
} from "@mantine/core";
import {
  IconGauge,
  IconPresentationAnalytics,
  IconFileAnalytics,
  IconAdjustments,
  TablerIconsProps,
  IconDatabase,
  IconLogout,
  IconMail,
  IconSettings,
  IconMicrophone,
  IconTicket,
  IconDiscountCheck,
} from "@tabler/icons-react";
import { UserButton } from "./UserButton";
import { LinksGroup } from "./NavbarLinksGroup";
import Logo from "./Logo";
import { useNavigate } from "react-router-dom";
import { useContext } from "react";
import { UserContext } from "../UserContext";

// @ts-ignore
// {
//   label: "Market news",
//   icon: IconNotes,
//   initiallyOpened: true,
//   links: [
//     { label: "Overview", link: "/Customize" },
//     { label: "Forecasts", link: "/" },
//     { label: "Outlook", link: "/" },
//     { label: "Real time", link: "/" },
//   ],
// },

// chatbot
// prompted
// tutor
// coach
// testing
type MenuLine = {
  label: string;
  icon: (props: TablerIconsProps) => JSX.Element;
  link?: string;
};

const chatbotMenu: MenuLine[] = [
  { label: "Add Data", icon: IconGauge, link: "/add-data" },
  { label: "Customize", icon: IconAdjustments, link: "/customize" },
  {
    label: "Preview (disabled)",
    icon: IconPresentationAnalytics /*, link: "/preview"*/,
  },
  { label: "Integrate", icon: IconFileAnalytics, link: "/integrate" },
  { label: "Inbox", icon: IconMail, link: "/inbox" },
  { label: "Pricing", icon: IconDiscountCheck, link: "/pricing" },
];
const promptedMenu: MenuLine[] = [
  { label: "Home", icon: IconGauge, link: "/prompted" },
  { label: "Courses", icon: IconFileAnalytics, link: "/courses" },
];
const tutorMenu: MenuLine[] = [
  { label: "Tutor Data", icon: IconDatabase, link: "/tutor" },
];
const coachMenu: MenuLine[] = [
  { label: "Voice", icon: IconMicrophone, link: "/voice" },
];
const testingMenu: MenuLine[] = [];

const linkSwitch = (project: string) => {
  switch (project) {
    case "chatbot":
      return chatbotMenu;
    case "prompted":
      return promptedMenu;
    case "tutor":
      return tutorMenu;
    case "coach":
      return coachMenu;
    case "testing":
      return [
        ...testingMenu,
        ...chatbotMenu,
        ...promptedMenu,
        ...tutorMenu,
        ...coachMenu,
      ];
    default:
      return [];
  }
};

const useStyles = createStyles((theme) => ({
  navbar: {
    backgroundColor:
      theme.colorScheme === "dark" ? theme.colors.dark[6] : theme.white,
    paddingBottom: 0,
  },

  header: {
    padding: theme.spacing.md,
    paddingTop: 0,
    marginLeft: `calc(${theme.spacing.md} * -1)`,
    marginRight: `calc(${theme.spacing.md} * -1)`,
    color: theme.colorScheme === "dark" ? theme.white : theme.black,
    borderBottom: `${rem(1)} solid ${
      theme.colorScheme === "dark" ? theme.colors.dark[4] : theme.colors.gray[3]
    }`,
  },

  links: {
    marginLeft: `calc(${theme.spacing.md} * -1)`,
    marginRight: `calc(${theme.spacing.md} * -1)`,
  },

  linksInner: {
    paddingTop: theme.spacing.xl,
    paddingBottom: theme.spacing.xl,
  },

  footer: {
    marginLeft: `calc(${theme.spacing.md} * -1)`,
    marginRight: `calc(${theme.spacing.md} * -1)`,
    borderTop: `${rem(1)} solid ${
      theme.colorScheme === "dark" ? theme.colors.dark[4] : theme.colors.gray[3]
    }`,
  },
}));

export function NavbarNested({ opened }: { opened: boolean }) {
  const { classes } = useStyles();
  const { user } = useContext(UserContext);
  const links = linkSwitch(user?.project ?? "").map((item) => (
    <LinksGroup {...item} key={item.label} />
  ));
  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.removeItem("user"); // Remove the user from local storage
    window.location.href = "/";
  };

  const handleOpenTicket = () => {
    const url = "https://voilabs.freshdesk.com/support/tickets/new";
    window.open(url, "_blank", "noopener,noreferrer");
  };

  return (
    <Navbar
      p="md"
      hiddenBreakpoint="sm"
      className={classes.navbar}
      hidden={!opened}
      width={{ sm: 200, md: 250, lg: 300 }}
    >
      <Navbar.Section className={classes.header}>
        <Group position="apart">
          <div
            onClick={() => navigate("/")}
            style={{
              cursor: window.location.pathname !== "/" ? "pointer" : undefined,
            }}
          >
            <Logo />
          </div>
          {/* <Code sx={{ fontWeight: 700 }} color="teal">
            BETA
          </Code> */}
        </Group>
      </Navbar.Section>

      <Navbar.Section grow className={classes.links} component={ScrollArea}>
        <div className={classes.linksInner}>{links}</div>
      </Navbar.Section>

      <Menu trigger="hover" openDelay={100} closeDelay={400}>
        <Menu.Target>
          <Navbar.Section className={classes.footer}>
            <UserButton
              image="https://images.unsplash.com/photo-1508214751196-bcfd4ca60f91?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=255&q=80"
              name={user?.username ?? ""}
              email={user?.email ?? ""}
            />
            <Menu.Dropdown>
              <Menu.Item
                icon={<IconSettings size={14} />}
                onClick={() => navigate("/settings")}
              >
                Settings
              </Menu.Item>
              <Menu.Item
                icon={<IconTicket size={14} />}
                onClick={handleOpenTicket}
              >
                Open a ticket
              </Menu.Item>
              <Menu.Item icon={<IconLogout size={14} />} onClick={handleLogout}>
                Disconnect
              </Menu.Item>
            </Menu.Dropdown>
          </Navbar.Section>
        </Menu.Target>
      </Menu>
    </Navbar>
  );
}
