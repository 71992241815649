import { BrowserRouter, Routes, Route } from "react-router-dom";
import Layout from "./Layout";
import VoiLabs from "./Pages/VoiLabs";
import EFK from "./Pages/EFK";
import { useContext } from "react";
import { UserContext } from "./UserContext";
import AppShellDemo from "./Pages/AppShellDemo";
import AddData from "./Pages/AddData";
import Customize from "./Pages/Customize";
import ChatWidget from "./Components/Widjet/ChatWidget";
import Integrate from "./Pages/Integrate";
import TermsAndConditions from "./Pages/TermsAndConditions";
import Preview from "./Pages/Preview";
import StudioHome from "./Pages/StudioHome";
import Inbox from "./Pages/Inbox";
import Test from "./Components/Test";
import Settings from "./Pages/Settings";
import Landing from "./Pages/Landing";
import Voice from "./Pages/Voice";
import Pricing from "./Pages/Pricing";
import Success from "./Pages/Success";
import TutorLanding from "./Pages/TutorLanding";
import Tutor from "./Pages/Tutor";
import PromptedLanding from "./Pages/PromptedLanding";
import PromptedHome from "./Pages/PromptedHome";
import PromptedCourseList from "./Pages/PromptedCourseList";
import PromptedCourse from "./Pages/PromptedCourse";

// dispatch the routes to the correct project

const tutorRoutes = [
  {
    path: "/tutor",
    element: <Tutor />,
  },
];

const chatbotRoutes = [
  {
    path: "/add-data",
    element: <AddData />,
  },
  {
    path: "/customize",
    element: <Customize />,
  },
  {
    path: "/demo",
    element: <AppShellDemo />,
  },
  {
    path: "/preview",
    element: <Preview />,
  },
  {
    path: "/integrate",
    element: <Integrate />,
  },
  {
    path: "/inbox",
    element: <Inbox />,
  },
  {
    path: "/inbox/:sessionId",
    element: <Inbox />,
  },
  {
    path: "/success",
    element: <Success />,
  },
  {
    path: "/studio",
    element: <StudioHome />,
  },
  {
    path: "/widget/:userId",
    element: <ChatWidget />,
  },
  {
    path: "/pricing",
    element: <Pricing />,
  },
  {
    path: "/success",
    element: <Success />,
  },
];

const promptedRoutes: { path: string; element: JSX.Element }[] = [
  {
    path: "/*",
    element: <PromptedHome />,
  },
  {
    path: "/prompted",
    element: <PromptedHome />,
  },
  {
    path: "/courses",
    element: <PromptedCourseList />,
  },
  {
    path: "/course",
    element: <PromptedCourse />,
  },
];

const coachRoutes = [
  {
    path: "/voice",
    element: <Voice />,
  },
];

const testingRoutes = [
  {
    path: "/email",
    element: <EFK />,
  },
];

const publicRoutes = [
  {
    path: "/consulting",
    element: <Landing />,
  },
  {
    path: "/TermsAndConditions",
    element: <TermsAndConditions />,
  },
  {
    path: "/test",
    element: <Test />,
  },
  {
    path: "/chatbot",
    element: <VoiLabs />,
  },
  {
    path: "/tutor",
    element: <TutorLanding />,
  },
  {
    path: "/prompted",
    element: <PromptedLanding />,
  },
  {
    path: "/widget/:userId",
    element: <ChatWidget />,
  },
];

const routesDispatch = (project: string) => {
  switch (project) {
    case "chatbot":
      return chatbotRoutes;
    case "prompted":
      return promptedRoutes;
    case "tutor":
      return tutorRoutes;
    case "coach":
      return coachRoutes;
    case "testing": // return all routes + testing routes
      return [
        ...testingRoutes,
        ...chatbotRoutes,
        ...promptedRoutes,
        ...tutorRoutes,
        ...coachRoutes,
      ];
    default:
      return [];
  }
};

export default function Router() {
  const { isLoggedIn, user } = useContext(UserContext);

  return (
    <BrowserRouter>
      <Routes>
        {isLoggedIn && (
          <>
            {routesDispatch(user?.project ?? "").map((route) => (
              <Route
                key={route.path}
                path={route.path}
                element={<Layout>{route.element}</Layout>}
              />
            ))}
            <Route
              path="/settings"
              element={
                <Layout>
                  <Settings />
                </Layout>
              }
            />
            <Route
              path="/*"
              element={
                <Layout>
                  <StudioHome />
                </Layout>
              }
            />
          </>
        )}
        <Route
          path="*"
          element={
            <Layout>
              <VoiLabs />
            </Layout>
          }
        />
        {publicRoutes.map((route) => (
          <Route
            key={route.path}
            path={route.path}
            element={<Layout>{route.element}</Layout>}
          />
        ))}
      </Routes>
    </BrowserRouter>
  );
}
