import {
  Box,
  Button,
  Container,
  Grid,
  Image,
  List,
  // Paper,
  Text,
  Title,
  createStyles,
} from "@mantine/core";
// import { FeatureVoiLabs } from "../Components/VoiLabs/Assets/FeatureVoiLabs";
// import PopupForm from "../Components/PopupForm";
import { useState } from "react";
import { useDisclosure } from "@mantine/hooks";
import { LoginRegisterModal } from "../Components/HeaderBar";
import work from "../Components/VoiLabs/Assets/work.png";
import VideoHeader from "../Components/VideoHeader";
import { BadgeCard } from "../Components/BadgeCard";

const useStyles = createStyles((theme) => ({
  control: {
    [theme.fn.smallerThan("xs")]: {
      flex: 1,
    },
  },
}));

const Landing = () => {
  const { classes } = useStyles();
  const [opened, { close }] = useDisclosure(false);
  const [state, setState] = useState("login");

  return (
    <Box style={{ textAlign: "center" }}>
      <LoginRegisterModal
        opened={opened}
        close={close}
        state={state}
        setState={setState}
      />
      <VideoHeader />

      <Container size="lg">
        <Grid m={0} mt={50} align="center">
          <Grid.Col xs={12} md={6}>
            <Image maw={340} mx="auto" radius="md" src={work} alt="work" />
          </Grid.Col>
          <Grid.Col xs={12} md={6}>
            <Text style={{ textAlign: "left", fontSize: 18 }}>
              <Title
                style={{
                  justifyContent: "left",
                  marginBottom: 10,
                  fontWeight: 700,
                }}
              >
                Bienvenue chez Voilabs - Votre partenaire pour la mise en oeuvre
                de solutions de GenAI
              </Title>
            </Text>
          </Grid.Col>
        </Grid>

        <Grid m={0} mt={30} mb={90}>
          <Grid.Col xs={6}>
            <BadgeCard
              // image={anaellePicture}
              title="Chaine San Buenaventura"
              description="Spécialiste en IA avec plus de huit ans d'expérience, connue pour ses solutions innovantes en Deep Learning, NLP, Computer Vision et Human-Machine Interaction."
              country="AI Engineer"
              badges={[]}
            />
          </Grid.Col>
          <Grid.Col xs={6}>
            <BadgeCard
              // image={anaellePicture}
              title="Benjamin Merchin"
              description="Expert en développement web et en solutions technologiques. Diplômé de 42 Paris et de l'ESTP Paris, avec une formation en entrepreneuriat à HEC Paris."
              country="Full-stack Engineer"
              badges={[]}
            />
          </Grid.Col>
          {/* <Grid.Col xs={4}>
            <BadgeCard
              // image={aymericPicture}
              title="Guillaume Otrage"
              description="Benjamin a été très à l'écoute et très réactif par rapport à mes besoins. Il a su aussi faire de preuve de créativité. Merci à lui"
              country="Product Manager"
              badges={[]}
            />
          </Grid.Col> */}
        </Grid>

        <Text style={{ textAlign: "left", fontSize: 18 }}>
          <Title
            style={{
              justifyContent: "left",
              marginBottom: 10,
              fontWeight: 700,
            }}
          >
            Solutions Personnalisées pour Vos Besoins en GenAI et Développement
            Web
          </Title>
          <List>
            <List.Item>
              Développement Web sur Mesure : Sites web, applications, et
              back-offices personnalisés.
            </List.Item>
            <List.Item>
              Intelligence Artificielle : Outils de formatage de CV, chatbots
              vocaux, et plus encore.
            </List.Item>
            <List.Item>
              Consultation et Stratégie en IA : Expertise en déploiement de
              solutions IA dans le cloud et sur le terrain.
            </List.Item>
          </List>
        </Text>

        <Title
          style={{
            justifyContent: "left",
            marginBottom: 10,
            fontWeight: 700,
          }}
          mt={40}
        >
          Nos Réalisations
        </Title>
        <Grid m={0} mt={30} mb={90}>
          <Grid.Col xs={4}>
            <BadgeCard
              // image={anaellePicture}
              title="Formattage de CV"
              description="Une plateforme pour reformatter automatiquement des CV sous un format standard"
              country="2023"
              badges={[]}
            />
          </Grid.Col>
          <Grid.Col xs={4}>
            <BadgeCard
              // image={anaellePicture}
              title="Plateforme "
              description="Une plateforme complète avec back office et application mobil pour la gestion interne d'une startup"
              country="2023"
              badges={[]}
            />
          </Grid.Col>
          <Grid.Col xs={4}>
            <BadgeCard
              // image={aymericPicture}
              title="Matching automatique de CV"
              description="Associer automatiquement un grand nombre de CV à des offres d'emploi adéquates"
              country="2023"
              badges={[]}
            />
          </Grid.Col>
        </Grid>

        {/* <FeatureVoiLabs /> */}
        {/* <div style={{ paddingRight: 20, paddingLeft: 20 }}>
        <CTA />
      </div> */}
        {/* <Paper m={10}>
          <PopupForm />
        </Paper> */}
        <Title
          style={{
            justifyContent: "left",
            marginBottom: 10,
            fontWeight: 700,
          }}
          mt={30}
        >
          Transformer Votre Idée en Réalité ?
        </Title>
        <Button
          radius="xl"
          size="md"
          className={classes.control}
          color="violet"
          // onClick={() => navigate("/ai")}
          m={10}
        >
          Contact: chaine[at]voilabs.com
        </Button>
      </Container>
    </Box>
  );
};
export default Landing;
